import {I18n} from '@lingui/core';
import {useLingui} from '@lingui/react';
import {MerchantAccountDetailsOutput} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {MerchantAccountPublicStatus, formatLocaleDate} from '@zentact/common';
import type {StoreStatus} from '@zentact/db';
import {DateTime} from 'luxon';
import {Link, generatePath} from 'react-router-dom';
import {FlatPillWithDot} from '../../elements';

export const getStoreLocalizedStatusMap = (i18n: I18n): Record<StoreStatus, string> => ({
  // biome-ignore lint/style/useNamingConvention: <explanation>
  ACTIVE: i18n._('Active'),
  // biome-ignore lint/style/useNamingConvention: <explanation>
  INACTIVE: i18n._('Inactive'),
  // biome-ignore lint/style/useNamingConvention: <explanation>
  CLOSED: i18n._('Closed'),
});

type PillProps = React.ComponentProps<typeof FlatPillWithDot>;
export const storeStatusToColor: Record<StoreStatus, PillProps['color']> = {
  // biome-ignore lint/style/useNamingConvention: <explanation>
  ACTIVE: 'green',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  INACTIVE: 'yellow',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  CLOSED: 'red',
};

type Props = {
  merchantAccount: MerchantAccountDetailsOutput;
  storeDetailsPath: string;
};

export const MerchantStoresList = ({merchantAccount, storeDetailsPath}: Props) => {
  const {i18n} = useLingui();
  return (
    <div className="flex flex-col gap-y-2">
      {merchantAccount.stores.map((store, i) => (
        <div key={`store-${i}`} className="flex gap-2 text-sm leading-6">
          <Link
            className="text-primary-600"
            to={generatePath(storeDetailsPath, {storeId: store.id})}
          >
            {store.displayName}
          </Link>
          <span>—</span>
          <FlatPillWithDot
            color={storeStatusToColor[store.status] || 'blue'}
            label={getStoreLocalizedStatusMap(i18n)[store.status] || store.status}
          />
          <span>—</span>
          {formatLocaleDate(store.createdAt, DateTime.DATE_MED)}
          {!store.balanceAccountId &&
            merchantAccount.status === MerchantAccountPublicStatus.ACTIVE && (
              <span className="font-medium text-red-600">{i18n._('NO BALANCE ACCOUNT SET')}</span>
            )}
        </div>
      ))}
    </div>
  );
};
