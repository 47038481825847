import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useDefaultSplitConfigurationGroup} from '@/hooks';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {feeGroupsSearchParamsSchema} from '@zentact/common';
import {SplitConfigurationGroupStatus} from '@zentact/db';
import {
  Breadcrumbs,
  Button,
  TableSortValue,
  Typography,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useEffect, useState} from 'react';
import {Link, generatePath, useNavigate} from 'react-router-dom';
import {SplitConfigurationGroupsList} from './split-configuration-groups-list';

const breadcrumbs = () => [
  {name: t`Configure`, href: RoutePath.CONFIGURE, current: false, disabled: true},
  {name: t`Transaction Fee Groups`, href: '#', current: true},
];

export const TransactionFeeGroups = () => {
  const {pspMerchantAccountName} = useStore();
  const navigate = useNavigate();
  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(
    feeGroupsSearchParamsSchema
  );
  const [filters, setFilters] = useState<{
    name?: string;
    status?: SplitConfigurationGroupStatus[];
  }>({name: typedSearchParams.name});
  const [sort, setSort] = useState<TableSortValue<string> | null>(null);
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 15});

  const splitConfigurationGroups = trpc.splitConfigurationGroup.list.useQuery(
    {
      ...pagination,
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
      where: {
        ...(filters.name && {name: filters.name}),
        ...(filters.status?.length && {status: filters.status}),
        ...(pspMerchantAccountName && {pspMerchantAccountName}),
      },
    },
    {refetchOnWindowFocus: true, keepPreviousData: true}
  );
  const {defaultSplitConfigurationGroup, refetch: refetchDefaultSplitConfigurationGroup} =
    useDefaultSplitConfigurationGroup();

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
    setTypedSearchParams(filters);
  }, [filters, sort]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="flex justify-between gap-2 py-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Transaction Fee Groups</Trans>
        </Typography>
        <Link to={RoutePath.TRANSACTION_FEE_GROUP_CREATE}>
          <Button type="button" variant="primary" size="md" className="w-fit max-sm:w-full">
            <Trans>Add New</Trans>
          </Button>
        </Link>
      </div>
      <div className="mt-4">
        <SplitConfigurationGroupsList
          splitConfigurationGroupsList={splitConfigurationGroups.data}
          refetch={() => {
            splitConfigurationGroups.refetch();
            refetchDefaultSplitConfigurationGroup();
          }}
          filters={filters}
          setFilters={setFilters}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          onRowClick={splitConfigurationGroupId =>
            navigate(
              generatePath(RoutePath.TRANSACTION_FEE_GROUP_DETAILS, {splitConfigurationGroupId})
            )
          }
          defaultSplitConfigurationGroup={defaultSplitConfigurationGroup}
          isLoading={
            splitConfigurationGroups.isLoading ||
            (splitConfigurationGroups.isRefetching && splitConfigurationGroups.isPreviousData)
          }
        />
      </div>
    </div>
  );
};
