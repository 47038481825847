import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {
  useDefaultSplitConfigurationGroup,
  useLocale,
  useRedirectWhenPspMerchantAccountChanges,
} from '@/hooks';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {SplitConfigurationGroupPublicStatus, fromMinorUnits} from '@zentact/common';
import {Breadcrumbs, Loading, Typography, useRequiredParams} from '@zentact/ui-tailwind';
import {useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {
  SplitConfigurationGroupFormData,
  TransactionFeeGroupForm,
  getSplitConfigurationGroupFormSchema,
} from './create-transaction-fee-group/transaction-fee-group-form/transaction-fee-group-form';
import {SplitConfigurationGroupActions} from './split-configuration-groups-list/split-configuration-group-actions';

export const TransactionFeeGroupDetailsPage = () => {
  useRedirectWhenPspMerchantAccountChanges(RoutePath.TRANSACTION_FEE_GROUPS);
  const {splitConfigurationGroupId} = useRequiredParams<{splitConfigurationGroupId: string}>();
  const {
    data: splitConfigurationGroupData,
    isLoading,
    refetch,
  } = trpc.splitConfigurationGroup.byId.useQuery(splitConfigurationGroupId, {
    enabled: !!splitConfigurationGroupId,
  });
  const {defaultSplitConfigurationGroup, refetch: refetchDefaultSplitConfigurationGroup} =
    useDefaultSplitConfigurationGroup();

  const {paymentMethods, currency} = useStore();
  const {locale} = useLocale();

  const isACHPaymentMethodEnabled = useMemo(
    () => paymentMethods?.includes('ACH'),
    [paymentMethods]
  );

  const form = useForm<SplitConfigurationGroupFormData>({
    resolver: zodResolver(
      getSplitConfigurationGroupFormSchema(locale, currency, isACHPaymentMethodEnabled)
    ),
  });

  useEffect(() => {
    if (!splitConfigurationGroupData) {
      return;
    }

    const {
      name,
      status,
      isDefault,
      cardPricingModel,
      cardSplitFixed,
      cardSplitPercentage,
      achSplitFixed,
      achSplitPercentage,
      paybybankSplitFixed,
      paybybankSplitPercentage,
      chargebackFeesModel,
      additionalChargebackFee,
      interacSplitFixed,
      amexSplitFixed,
      amexSplitPercentage,
      refundSplitModel,
      refundFeesModel,
      additionalRefundFee,
      overrideFeeSectionContent,
      showOverrideFeeInput,
    } = splitConfigurationGroupData;

    form.reset({
      name: name,
      isActive: status === SplitConfigurationGroupPublicStatus.ACTIVE,
      isDefault,
      cardPricingModel: cardPricingModel,
      cardSplitFixed: cardSplitFixed ? fromMinorUnits(cardSplitFixed, currency) : 0,
      cardSplitPercentageFlat:
        cardPricingModel === 'FLAT' && cardSplitPercentage
          ? fromMinorUnits(cardSplitPercentage, currency)
          : 0,
      cardSplitPercentageCostPlus:
        cardPricingModel === 'COST_PLUS' && cardSplitPercentage ? cardSplitPercentage : 0,
      achFixedOrPercentage: achSplitFixed ? 'FIXED' : 'PERCENTAGE',
      achSplitFixed: achSplitFixed ? fromMinorUnits(achSplitFixed, currency) : undefined,
      achSplitPercentage: achSplitPercentage ? fromMinorUnits(achSplitPercentage, currency) : 0,
      paybybankSplitFixed: paybybankSplitFixed ? fromMinorUnits(paybybankSplitFixed, currency) : 0,
      paybybankSplitPercentage: paybybankSplitPercentage
        ? fromMinorUnits(paybybankSplitPercentage, currency)
        : 0,
      interacSplitFixed: interacSplitFixed ? fromMinorUnits(interacSplitFixed, currency) : 0,
      amexSplitFixed: amexSplitFixed ? fromMinorUnits(amexSplitFixed, currency) : undefined,
      amexSplitPercentage: amexSplitPercentage ? fromMinorUnits(amexSplitPercentage, currency) : 0,
      chargebackFeesModel: chargebackFeesModel,
      additionalChargebackFee: additionalChargebackFee
        ? fromMinorUnits(additionalChargebackFee, currency)
        : 0,
      refundSplitModel: refundSplitModel,
      refundFeesModel: refundFeesModel,
      additionalRefundFee: additionalRefundFee ? fromMinorUnits(additionalRefundFee, currency) : 0,
      overrideFeeSectionContent: overrideFeeSectionContent || undefined,
      showOverrideFeeInput,
    });
  }, [splitConfigurationGroupData]);

  const breadcrumbs = useMemo(
    () => [
      {name: t`Configure`, href: RoutePath.CONFIGURE, current: false, disabled: true},
      {name: t`Transaction Fee Groups`, href: RoutePath.TRANSACTION_FEE_GROUPS, current: false},
      {
        name: splitConfigurationGroupData?.name ?? t`Details`,
        href: RoutePath.TRANSACTION_FEE_GROUP_DETAILS,
        current: true,
      },
    ],
    [splitConfigurationGroupData]
  );

  if (isLoading || !splitConfigurationGroupData) {
    return (
      <div className="flex items-center justify-center h-80">
        <Loading mode="inline" />
      </div>
    );
  }

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="flex items-center justify-between mt-4">
        <Typography variant="header-page">{splitConfigurationGroupData?.name}</Typography>
        <SplitConfigurationGroupActions
          splitConfigurationGroup={splitConfigurationGroupData}
          refetchData={() => {
            refetch();
            refetchDefaultSplitConfigurationGroup();
          }}
          defaultSplitConfigurationGroup={defaultSplitConfigurationGroup}
          view="button"
        />
      </div>

      <div className="py-3 text-sm text-gray-700">
        <Trans>
          Set up how to charge your merchants. You can have a default set of fees and create custom
          configurations for different circumstances. You will apply these when adding a new
          merchant. Monthly fees will apply to all merchants onboarded by the 20th day of the month.
        </Trans>
      </div>
      <form>
        <TransactionFeeGroupForm
          form={form}
          readOnly
          storesCount={splitConfigurationGroupData?.storesCount}
        />
      </form>
    </div>
  );
};
