import {useLingui} from '@lingui/react';
import {CurrencyCode} from '@zentact/common';
import {ChartDataWithSettingsType} from '@zentact/common/src/utils/transactionsChart/charts';
import ReactApexChart from 'react-apexcharts';
import {Typography} from '../typography';

export type TransactionsChartPropsType = {
  chartData?: ChartDataWithSettingsType;
  isLoading?: boolean;
  currency?: CurrencyCode;
};

export const TransactionsChart = ({chartData, currency}: TransactionsChartPropsType) => {
  const {type = 'area', height = 350} = (chartData?.options?.chart as ApexChart) || {};
  const {i18n} = useLingui();
  return (
    <div className="mt-5 overflow-hidden bg-white rounded-lg shadow">
      <div className="py-5 pl-4 pr-2 sm:pl-6 sm:pr-2">
        <Typography className="mb-5" variant="header-section">
          {i18n._('Volume of Transactions')}
        </Typography>
        <ReactApexChart {...chartData} type={type} height={Number(height) + 30} key={currency} />
      </div>
    </div>
  );
};
