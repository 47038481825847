import {useLingui} from '@lingui/react';
import {MerchantAccountPublicStatus, StorePublicStatus, StoresFilters} from '@zentact/common';
import {MerchantAccountStatus} from '@zentact/db';
import {useCallback, useEffect} from 'react';
import {MerchantAccountsPickerFilter} from '../dropdowns';
import {getStoreLocalizedStatusMap, storeStatusToColor} from '../layout';
import {ResetTableFiltersButton} from '../table';
import {CheckboxFilter} from './CheckboxFilter';
import {EntityPickerFilter} from './EntityPickerFilter';
import {FlatPillWithDot} from './FlatPillWithDot';
import {InputSearchFilter} from './InputSearchFilter';

type Props<T extends StoresFilters> = {
  typedSearchParams: T;
  setTypedSearchParams: (params: T) => void;
  selectedMerchantAccount?: string;
  merchantAccounts?: {
    id: string;
    businessName: string;
    organizationId: string | null;
    status: MerchantAccountStatus | MerchantAccountPublicStatus;
    onboardedAt: string | null;
  }[];
  statuses?: string[];
  organizationId?: string;
  displayName?: string;
  organizationList?: {id: string; name: string}[];
};

export const StoreFilters = <T extends StoresFilters>({
  typedSearchParams,
  setTypedSearchParams,
  organizationId,
  selectedMerchantAccount,
  merchantAccounts,
  statuses,
  displayName,
  organizationList,
}: Props<T>) => {
  const {i18n} = useLingui();

  const handleFilterChange = useCallback(
    <K extends keyof T>(key: K, value: T[K]) => {
      setTypedSearchParams({[key]: value} as unknown as T);
    },
    [setTypedSearchParams]
  );

  useEffect(() => {
    const isOrganizationMerchant = merchantAccounts
      ?.filter(merchant => merchant.organizationId === organizationId)
      .some(merchant => merchant.id === selectedMerchantAccount);
    if (organizationId && !isOrganizationMerchant) {
      setTypedSearchParams({selectedMerchantAccount: undefined} as unknown as T);
    }
  }, [
    setTypedSearchParams,
    typedSearchParams.organizationId,
    merchantAccounts,
    typedSearchParams.selectedMerchantAccount,
  ]);

  const handleMerchantChange = useCallback(
    (value: string | undefined) => {
      handleFilterChange('selectedMerchantAccount', value);
    },
    [handleFilterChange]
  );

  return (
    <div className="flex flex-wrap items-center justify-end gap-2 lg:flex-nowrap">
      <div className="block lg:hidden 2xl:block max-lg:w-full">
        <ResetTableFiltersButton
          defaultFilters={{}}
          activeFilters={typedSearchParams}
          setFilters={setTypedSearchParams}
        />
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        <InputSearchFilter
          label="Store"
          selected={displayName}
          onChange={value => handleFilterChange('displayName', value)}
        />
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        {organizationList && (
          <EntityPickerFilter
            selected={organizationId || ''}
            onChange={value => handleFilterChange('organizationId', value)}
            options={organizationList}
          />
        )}
      </div>
      {!!merchantAccounts?.length && (
        <div className="font-normal shrink-0 max-lg:w-full">
          <MerchantAccountsPickerFilter
            selectedMerchantAccount={selectedMerchantAccount || ''}
            onSelectMerchantAccount={handleMerchantChange}
            merchantAccountsOptions={
              organizationId
                ? merchantAccounts.filter(
                    merchantAccount => merchantAccount.organizationId === organizationId
                  )
                : merchantAccounts
            }
            allLabel={i18n._('Merchant')}
          />
        </div>
      )}
      <div className="font-normal shrink-0 max-lg:w-full">
        <CheckboxFilter
          label={i18n._('Status')}
          contentClass="right-0 overflow-x-hidden"
          selected={statuses}
          onChange={value => handleFilterChange('statuses', value)}
          options={Object.keys(storeStatusToColor).map(status => ({
            element: (
              <FlatPillWithDot
                color={storeStatusToColor[status as StorePublicStatus] || 'blue'}
                label={getStoreLocalizedStatusMap(i18n)[status as StorePublicStatus]}
              />
            ),
            key: status,
          }))}
        />
      </div>
    </div>
  );
};
