import {InputSelect} from '../';

type Props<T extends string> = {
  label?: string;
  selected: T[];
  className?: string;
  options: {id: T; name: string}[];
  onChange: (ids: T[]) => void;
};

export const MultiEntityPicker = <T extends string>({
  label,
  options,
  onChange,
  className,
  selected,
}: Props<T>) => {
  return (
    <InputSelect
      label=""
      value={selected}
      labelClassName="gap-0"
      placeholder={label}
      className={className ? className : 'mt-0 w-60'}
      onChange={items => (Array.isArray(items) ? onChange(items as T[]) : onChange([items as T]))}
      options={options.map(o => ({id: o.id, label: o.name}))}
      multiple={options.length > 1}
    />
  );
};
