import {Tab} from '@headlessui/react';
import {Trans} from '@lingui/macro';
import {InputToggle, SanitizeHTML, Section, cn} from '@zentact/ui-tailwind';
import {UseFormRegister} from 'react-hook-form';
import {SplitConfigurationGroupFormData} from './transaction-fee-group-form';

type Props = {
  readOnly?: boolean;
  html?: string;
  showOverrideFeeInput: boolean;
  register: UseFormRegister<SplitConfigurationGroupFormData>;
};

export const OverridePricingSection = ({register, html, showOverrideFeeInput, readOnly}: Props) => {
  return (
    <Section
      title={
        <Trans>
          Override Fee Section Display Under Terms & Conditions Page During Merchant Onboarding
        </Trans>
      }
      subTitle={
        <Trans>
          <p>
            This text is shown during merchant registration to provide relevant fee details. Note
            that this information is for display purposes only; it does not impact any actual fee
            calculations or pricing structures. When using custom text, please ensure accuracy to
            make sure your customers understand the fees and to avoid potential misunderstandings.
            (Hint: To apply styles use inline CSS like{' '}
            {'<h2 style="color: #333; font-size: 1.5em; margin-bottom: 10px;">Fee Schedule</h2>'})
          </p>
        </Trans>
      }
    >
      <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
        <InputToggle
          toggleSize="lg"
          {...register('showOverrideFeeInput')}
          readOnly={readOnly}
          disabled={readOnly}
          label={<Trans>Use Custom Text</Trans>}
        />
        {showOverrideFeeInput && (
          <Tab.Group>
            <Tab.List className="flex items-center group">
              <Tab
                className={({selected}) =>
                  cn(
                    'ml-2 rounded-md border border-transparent bg-white px-3 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                    selected ? 'bg-gray-100 text-gray-900 hover:bg-gray-200' : ''
                  )
                }
              >
                Html
              </Tab>
              <Tab
                className={({selected}) =>
                  cn(
                    'ml-2 rounded-md border border-transparent bg-white px-3 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                    selected ? 'bg-gray-100 text-gray-900 hover:bg-gray-200' : ''
                  )
                }
              >
                Preview
              </Tab>
            </Tab.List>
            <Tab.Panels className="mt-2">
              <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
                <div>
                  <textarea
                    {...register('overrideFeeSectionContent')}
                    rows={5}
                    disabled={readOnly}
                    readOnly={readOnly}
                    placeholder="Enter the HTML here..."
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm/6"
                    defaultValue={''}
                  />
                </div>
              </Tab.Panel>
              <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
                <div className="border-b">
                  {html && html.length > 0 ? (
                    <div className="px-4 py-6 text-sm text-gray-800 sm:p-8 max-h-[400px] overflow-y-auto">
                      <SanitizeHTML html={html} />
                    </div>
                  ) : (
                    <div className="px-3 pt-2 pb-12 mx-px mt-px text-sm text-gray-800">
                      Preview content will render here.
                    </div>
                  )}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        )}
      </div>
    </Section>
  );
};
