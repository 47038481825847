import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useLocale, useRedirectWhenPspMerchantAccountChanges} from '@/hooks';
import {useStore} from '@/store';
import {PencilSquareIcon} from '@heroicons/react/24/outline';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {StoreDetailsOutput, StoresListItem} from '@zentact/api/src/trpc/routers/storeRouter';
import {
  CurrencyCode,
  ErrorCode,
  allIndustryGroups,
  formatAmount,
  formatLocaleDate,
  formatPercentage,
  isFormattedTrpcError,
} from '@zentact/common';
import {MerchantAccountPublicStatus} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  DashboardDatepicker,
  EntityPicker,
  FlatPillWithDot,
  InitiateTransferOverlay,
  Loading,
  PaymentMethodsPieChart,
  StoreEventsList,
  TruncatedText,
  Typography,
  getCurrencyCodeTranslation,
  getDashboardDatepickerDefaults,
  getStoreLocalizedStatusMap,
  storeStatusToColor,
  useRequiredParams,
  useToggleWithData,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {DateTime} from 'luxon';
import {useCallback, useState} from 'react';
import {DeepPartial} from 'react-hook-form';
import {Link, generatePath, useLocation, useNavigate} from 'react-router-dom';
import {z} from 'zod';
import {AddStoreFormData} from './add-store/add-store-form';
import {AssignOrganizationDialog} from './assign-organization-dialog';
import {AssignStoreTransactionFeeGroupPanel} from './assign-store-transaction-fee-group-panel';
import {EditOrganizationName} from './edit-organization-name';
import {EditStorePanel} from './edit-store';
import {InternalTransfersList} from './internal-transfers-list';
import {StoreActions} from './store-actions';
import {UpdateStoreSettlementDelayDays} from './update-store-settlement-delay-days';

const getBreadCrumbs = (
  merchantAccountName?: string,
  merchantAccountId?: string,
  storeName?: string
) => [
  {name: t`Customers`, href: RoutePath.CUSTOMERS_MERCHANTS, current: false},
  {name: t`Merchants`, href: RoutePath.CUSTOMERS_MERCHANTS, current: false},
  ...(merchantAccountName && merchantAccountId
    ? [
        {
          name: merchantAccountName,
          href: generatePath(RoutePath.MERCHANT_DETAILS, {merchantAccountId}),
          current: false,
        },
      ]
    : []),
  ...(storeName ? [{name: storeName, href: '#', current: true}] : []),
];

const searchParamsSchema = z.object({
  startDate: z
    .date()
    .or(z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').toJSDate()))
    .nullable()
    .optional(),
  endDate: z
    .date()
    .or(
      z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').endOf('day').toJSDate())
    )
    .nullable()
    .optional(),
  editStorePanelOpen: z
    .boolean()
    .or(z.string().transform(value => value === 'true'))
    .nullable()
    .optional(),
});

export const StoreDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {tenant, currency} = useStore();
  const {i18n} = useLingui();
  const {locale} = useLocale();
  const {storeId} = useRequiredParams<{storeId: string}>();
  const [isAssignOrganizationDialogOpen, setAssignOrganizationDialogOpen] = useState(false);
  const [isInitiateTransferOverlayOpen, setInitiateTransferOverlayOpen] = useState(false);
  useRedirectWhenPspMerchantAccountChanges(RoutePath.CUSTOMERS_MERCHANTS);
  const editStoreFormData: DeepPartial<AddStoreFormData> = location?.state?.addStoreFormData;

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(searchParamsSchema);

  const startDate = typedSearchParams?.startDate;
  const endDate = typedSearchParams?.endDate;
  const dateValue = startDate && endDate ? {startDate, endDate} : getDashboardDatepickerDefaults();

  const handleDateChange = useCallback(
    (dateRange: {startDate: Date; endDate: Date}) => {
      setTypedSearchParams({
        ...dateRange,
      });
    },
    [setTypedSearchParams]
  );
  const handleSelectStore = useCallback(
    (storeId?: string) => {
      if (!storeId) {
        return;
      }
      navigate(generatePath(RoutePath.STORE_DETAILS, {storeId}));
    },
    [navigate]
  );

  const {
    data: store,
    isLoading: storeIsLoading,
    refetch: storeRefetch,
  } = trpc.store.getStoreById.useQuery(
    {storeId},
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onError: error => {
        const errorCode = isFormattedTrpcError(error)
          ? error.data.errorCode
          : ErrorCode.ERROR_GENERIC;
        navigate(RoutePath.ERROR, {state: {errorCode}});
      },
    }
  );

  const merchantAccountProfileStats =
    trpc.merchantAccount.getMerchantAccountProfileStatistics.useQuery(
      {
        merchantAccountId: store?.merchantAccountId ?? '',
        storeId,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...(dateValue && {
          fromDate: dateValue.startDate,
          toDate: dateValue.endDate,
        }),
      },
      {
        enabled: !!store?.merchantAccountId,
        keepPreviousData: true,
        onError: error => {
          const errorCode = isFormattedTrpcError(error)
            ? error.data.errorCode
            : ErrorCode.ERROR_GENERIC;
          navigate(RoutePath.ERROR, {state: {errorCode}});
        },
      }
    );

  const balanceAccountBalances = trpc.balanceAccount.getBalanceAccountBalances.useQuery(
    {
      balanceAccountId: store?.balanceAccountId ?? '',
    },
    {
      enabled: !!store?.balanceAccountId,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialData: [],
      onError: error => {
        const errorCode = isFormattedTrpcError(error)
          ? error.data.errorCode
          : ErrorCode.ERROR_GENERIC;
        navigate(RoutePath.ERROR, {state: {errorCode}});
      },
    }
  );

  const ongoingTransfers = trpc.balanceAccount.getOngoingInternalTransfers.useQuery(
    {
      merchantBalanceAccountId: store?.balanceAccountId ?? '',
    },
    {
      enabled: !!store?.balanceAccountId,
      keepPreviousData: true,
      initialData: {rows: []},
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
    }
  );

  const {
    data: assignStoreFeeGroupData,
    on: openAssignStoreFeeGroupPanel,
    off: closeAssignStoreFeeGroupPanel,
  } = useToggleWithData<StoreDetailsOutput | StoresListItem | null>();

  const {
    data: updateSettlementDelayDays,
    on: openUpdateSettlementDelayDays,
    off: closeUpdateSettlementDelayDays,
  } = useToggleWithData<StoreDetailsOutput | StoresListItem | null>();

  if (
    storeIsLoading ||
    !store ||
    merchantAccountProfileStats.isLoading ||
    !merchantAccountProfileStats.data ||
    balanceAccountBalances.isLoading
  ) {
    return (
      <div className="flex items-center justify-center h-80">
        <Loading mode="inline" />
      </div>
    );
  }

  const {
    splitConfigurationGroup,
    organization,
    status,
    updatedAt,
    createdAt,
    merchantAccount: {
      id: merchantAccountId,
      businessName,
      status: merchantStatus,
      legalBusinessName,
      onboardedAt,
      stores,
      tenantIntervalFeesGroup,
    },
    storeBusinessLines,
    balanceAccount,
  } = store;
  const industryCodes = Array.from(
    new Set(
      storeBusinessLines.map(b => b.businessLine.industryCode).filter((v): v is string => !!v)
    )
  );
  const {settlementDelayDaysOverride, settlementDelayDaysOverrideAch} = balanceAccount ?? {};

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs(businessName, merchantAccountId, store.displayName)} />
      <div className="flex justify-between gap-2 py-4 max-lg:flex-col lg:items-center">
        <div>
          <Typography variant="header-page" className="flex">
            <TruncatedText text={`${businessName} — ${store.displayName}`} />
          </Typography>
        </div>
        <div className="flex gap-2 font-normal max-lg:flex-wrap lg:items-center">
          <div className="w-full shrink-0 lg:w-60">
            <EntityPicker
              label={t`All Stores`}
              selected={store.id}
              onChange={handleSelectStore}
              options={stores.map(storeItem => ({name: storeItem.displayName, id: storeItem.id}))}
              className="w-full"
              excludeDefaultOption
            />
          </div>
          <div className="w-full shrink-0 lg:mt-0 lg:w-64">
            <DashboardDatepicker dateRange={dateValue} onChange={handleDateChange} />
          </div>
          <div className="w-full shrink-0 lg:mt-0 lg:w-fit">
            {status !== 'CLOSED' && (
              <StoreActions
                row={store}
                openAssignStoreFeeGroupPanel={openAssignStoreFeeGroupPanel}
                openUpdateSettlementDelayDays={openUpdateSettlementDelayDays}
                openEditStorePanel={() => setTypedSearchParams({editStorePanelOpen: true})}
                viewType="chevron-button"
                triggerRefetch={storeRefetch}
              />
            )}
          </div>
        </div>
      </div>
      {!!onboardedAt && (
        <>
          <div className="grid gap-4 pb-5 max-md:gap-4 md:grid-cols-3">
            <div className="p-5 bg-white shadow rounded-xl md:col-span-1">
              <div className="mb-3 leading-6 text-gray-600 text-medium ">
                <Trans>Last Process Date</Trans>
              </div>
              <div className="text-3xl font-bold text-gray-800">
                {merchantAccountProfileStats.data.lastPaymentDate
                  ? formatLocaleDate(
                      merchantAccountProfileStats.data.lastPaymentDate,
                      DateTime.DATE_MED
                    )
                  : '-'}
              </div>
            </div>
            <div className="p-5 bg-white shadow rounded-xl md:col-span-1">
              <div className="mb-3 leading-6 text-gray-600 text-medium ">
                <Trans>Processed</Trans>
              </div>
              <div className="text-3xl font-bold text-gray-800">
                {formatAmount(merchantAccountProfileStats.data.totalTxVolume, locale, currency)}
              </div>
            </div>
            <div className="p-5 bg-white shadow rounded-xl md:col-span-1">
              <div className="mb-3 leading-6 text-gray-600 text-medium ">
                <Trans>Total Profit</Trans>
              </div>
              <div className="text-3xl font-bold text-gray-800">
                {formatAmount(merchantAccountProfileStats.data.totalProfit, locale, currency)}
              </div>
            </div>
          </div>
        </>
      )}
      {merchantAccountProfileStats.data.totalTxCount > 0 && (
        <div className="py-5 mb-5 bg-white shadow rounded-xl">
          <div className="px-4 pb-5 mb-5 border-b border-gray-200 sm:px-6">
            <Typography variant="header-section">
              <Trans>Transaction Statistics</Trans>
            </Typography>
          </div>
          <div className="grid gap-4 px-4 md:grid-cols-2 sm:px-6">
            <div className="leading-6 border-gray-100 sm:col-span-1">
              <dl className="divide-y divide-gray-100">
                <div className="pb-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Average Monthly Volume</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatAmount(
                      merchantAccountProfileStats.data.avergeMonthlyVolume,
                      locale,
                      currency
                    )}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Transaction Count</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {merchantAccountProfileStats.data.totalTxCount}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Average Transaction Amount</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatAmount(
                      merchantAccountProfileStats.data.averageTxAmount,
                      locale,
                      currency
                    )}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Chargeback Count</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {merchantAccountProfileStats.data.totalChargebackCount}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Chargeback Volume</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatAmount(
                      merchantAccountProfileStats.data.totalChargebackVolume,
                      locale,
                      currency
                    )}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Average Chargeback Amount</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatAmount(
                      merchantAccountProfileStats.data.averageChargebackAmount,
                      locale,
                      currency
                    )}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Chargeback Percentage</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatPercentage(
                      merchantAccountProfileStats.data.totalTxCount,
                      merchantAccountProfileStats.data.totalChargebackCount,
                      locale
                    )}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="md:col-span-1">
              <PaymentMethodsPieChart
                paymentMethods={merchantAccountProfileStats.data.paymentMethodStats}
                locale={locale}
              />
            </div>
          </div>
        </div>
      )}
      {balanceAccountBalances.data.length > 0 && (
        <div className="py-5 mb-5 bg-white shadow rounded-xl md:col-span-2">
          <div className="flex justify-between px-4 pb-5 border-b border-gray-200 sm:px-6">
            <Typography variant="header-section">
              <Trans>Balances</Trans>
            </Typography>
            {status === MerchantAccountPublicStatus.ACTIVE && (
              <Button
                variant="primary"
                size="lg"
                className="w-fit"
                onClick={() => setInitiateTransferOverlayOpen(true)}
              >
                <Trans>Initiate Transfer</Trans>
              </Button>
            )}
          </div>
          <div className="px-4 leading-6 divide-y divide-gray-100 sm:px-6">
            {!!store.balanceAccountId && (
              <div className="py-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Adyen Balance Account ID</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  {store.balanceAccountId}
                </dd>
              </div>
            )}
            {balanceAccountBalances.data.map((balance, index) => (
              <div key={`balance-${index}`} className="py-3">
                <dt className="pb-1 text-sm font-medium text-gray-900">
                  {getCurrencyCodeTranslation(balance.currency, i18n)}
                </dt>
                <div className="px-2">
                  <div className="sm:flex max-sm:mt-1">
                    <dt className="text-sm font-medium text-gray-900 sm:w-[192px] sm:mr-6">
                      {balance.currency} <Trans>Balance</Trans>
                    </dt>
                    <dd className="text-sm text-gray-700 break-words sm:mt-0">
                      {formatAmount(balance.balance, locale, balance.currency)}
                    </dd>
                  </div>
                  <div className="sm:flex max-sm:mt-1">
                    <dt className="text-sm font-medium text-gray-900 sm:w-[192px] sm:mr-6">
                      {balance.currency} <Trans>Pending</Trans>
                    </dt>
                    <dd className="text-sm text-gray-700 break-words sm:mt-0">
                      {formatAmount(balance.pending, locale, balance.currency)}
                    </dd>
                  </div>
                  <div className="sm:flex max-sm:mt-1">
                    <dt className="text-sm font-medium text-gray-900 sm:w-[192px] sm:mr-6">
                      {balance.currency} <Trans>Reserved</Trans>
                    </dt>
                    <dd className="text-sm text-gray-700 break-words sm:mt-0">
                      {formatAmount(balance.reserved, locale, balance.currency)}
                    </dd>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {ongoingTransfers.data.rows?.length > 0 && (
        <div className="pt-5 mb-5 bg-white shadow lg:pb-5 rounded-xl md:col-span-2">
          <div className="flex px-4 pb-5 mb-4 border-b border-gray-200 sm:px-6">
            <Typography variant="header-section">
              <Trans>Ongoing Transfers</Trans>
            </Typography>{' '}
          </div>
          <div className="leading-6 lg:px-6">
            <InternalTransfersList
              list={ongoingTransfers.data}
              isLoading={ongoingTransfers.isLoading}
              refetch={() => {
                ongoingTransfers.refetch();
              }}
            />
          </div>
        </div>
      )}
      <div className="py-5 mb-5 bg-white shadow rounded-xl md:col-span-2">
        <div className="px-4 pb-5 mb-5 border-b border-gray-200 sm:px-6">
          <Typography variant="header-section">
            <Trans>General</Trans>
          </Typography>
        </div>
        <div className="px-4 sm:px-6">
          <div className="leading-6 border-gray-100 ">
            <dl className="divide-y divide-gray-100">
              <div className="pb-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Store Name</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  {store.displayName}
                </dd>
              </div>
              {!!legalBusinessName && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Legal Business Name</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {legalBusinessName}
                  </dd>
                </div>
              )}
              <div className="py-3 sm:flex sm:items-center">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Organization</Trans>
                </dt>
                <dd className="flex items-center text-sm text-gray-700 break-words">
                  <div className="flex items-center gap-x-2">
                    {!organization.notAssigned ||
                    merchantStatus === MerchantAccountPublicStatus.ASSIGNING_ORGANIZATION ? (
                      <>
                        <Link
                          to={`${RoutePath.CUSTOMERS_MERCHANTS}?organizationId=${organization.id}`}
                          className="text-primary-600 hover:underline"
                        >
                          {organization.name}
                        </Link>
                        <EditOrganizationName
                          organizationName={organization.name}
                          organizationId={organization.id}
                          refetch={storeRefetch}
                          pspMerchantAccountName={store.pspMerchantAccountName}
                        />
                      </>
                    ) : (
                      <>
                        <p className="text-sm italic text-gray-500">
                          <Trans>Not Assigned</Trans>
                        </p>
                        <Button
                          variant="primary"
                          size="lg"
                          className="w-fit"
                          onClick={() => setAssignOrganizationDialogOpen(true)}
                        >
                          <Trans>Assign Organization</Trans>
                        </Button>
                      </>
                    )}
                  </div>
                </dd>
              </div>
              <div className="py-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Settlement Delay</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  <Trans>T+</Trans>
                  {settlementDelayDaysOverride || tenant?.settlementDelayDays}
                </dd>
              </div>
              {settlementDelayDaysOverrideAch && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Settlement Delay ACH</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    <Trans>T+</Trans>
                    {settlementDelayDaysOverrideAch}
                  </dd>
                </div>
              )}
              <div className="py-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Status</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  <FlatPillWithDot
                    color={storeStatusToColor[status] || 'blue'}
                    label={getStoreLocalizedStatusMap(i18n)[status] || status}
                  />
                </dd>
              </div>
              {!!splitConfigurationGroup && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Transaction Fee Group</Trans>
                  </dt>
                  <dd className="flex mt-1 text-sm text-gray-700 break-words sm:mt-0 gap-x-2">
                    <Link
                      to={generatePath(RoutePath.TRANSACTION_FEE_GROUP_DETAILS, {
                        splitConfigurationGroupId: splitConfigurationGroup.id,
                      })}
                      className="text-primary-600 hover:underline"
                    >
                      {splitConfigurationGroup.name}
                    </Link>
                    <div className="flex items-center justify-end">
                      <PencilSquareIcon
                        className="w-5 h-5 cursor-pointer text-primary-600"
                        onClick={() => openAssignStoreFeeGroupPanel(store)}
                      />
                    </div>
                  </dd>
                </div>
              )}
              {!!tenantIntervalFeesGroup && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Recurring Fee Group</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    <Link
                      to={generatePath(RoutePath.RECURRING_FEE_GROUP_DETAILS, {
                        tenantIntervalFeeGroupId: tenantIntervalFeesGroup.id,
                      })}
                      className="text-primary-600 hover:underline"
                    >
                      {tenantIntervalFeesGroup.name}
                    </Link>{' '}
                    <span className="italic">
                      <Trans>Applies to all Stores</Trans>
                    </span>
                  </dd>
                </div>
              )}
              {industryCodes.length > 0 && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Industry</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {industryCodes
                      .map(
                        industryCode =>
                          `${
                            allIndustryGroups[industryCode as keyof typeof allIndustryGroups] ??
                            t`Unrecognized`
                          } (${industryCode})`
                      )
                      .join(', ')}
                  </dd>
                </div>
              )}
              <div className="py-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Created At</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  {formatLocaleDate(createdAt)}
                </dd>
              </div>
              <div className="py-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Last Updated At</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  {formatLocaleDate(updatedAt)}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="py-5 mb-5 bg-white shadow rounded-xl md:col-span-2">
        <div className="px-4 pb-5 mb-5 border-b border-gray-200 sm:px-6">
          <Typography variant="header-section">
            <Trans>Technical Information</Trans>
          </Typography>
        </div>
        <div className="px-4 sm:px-6">
          <div className="leading-6 border-gray-100 ">
            <dl className="divide-y divide-gray-100">
              <div className="pb-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Merchant Account ID</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  {store.merchantAccount.id}
                </dd>
              </div>
              {!!store.merchantAccount.tenantMerchantReferenceId && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Merchant Reference ID</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {store.merchantAccount.tenantMerchantReferenceId}
                  </dd>
                </div>
              )}
              <div className="py-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Adyen Store ID</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">{storeId}</dd>
              </div>
              {!!store.storeReferenceId && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Store Reference ID</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {store.storeReferenceId}
                  </dd>
                </div>
              )}
              {!!store.balanceAccountId && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Adyen Balance Account ID</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {store.balanceAccountId}
                  </dd>
                </div>
              )}
              {!!splitConfigurationGroup?.adyenSplitConfigurationId && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Adyen Split Configuration ID</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {splitConfigurationGroup.adyenSplitConfigurationId}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </div>
      </div>
      <div className="py-5 mb-5 bg-white shadow rounded-xl md:col-span-2">
        <div className="px-4 pb-5 mb-5 border-b border-gray-200 sm:px-6">
          <Typography variant="header-section">
            <Trans>Store History</Trans>
          </Typography>
        </div>
        <div className="px-4 sm:px-6">
          <StoreEventsList
            store={store}
            transactionFeeGroupListPath={RoutePath.TRANSACTION_FEE_GROUPS}
          />
        </div>
      </div>
      <AssignOrganizationDialog
        isOpen={isAssignOrganizationDialogOpen}
        setOpen={setAssignOrganizationDialogOpen}
        merchantAccountId={store.merchantAccount.id}
        businessName={store.merchantAccount.businessName}
        triggerRefetch={storeRefetch}
      />
      <EditStorePanel
        storeId={store.id}
        isOpen={!!typedSearchParams.editStorePanelOpen}
        onClose={() => setTypedSearchParams({editStorePanelOpen: false})}
        triggerRefetch={storeRefetch}
        formDefaultValue={editStoreFormData}
      />
      <InitiateTransferOverlay
        open={isInitiateTransferOverlayOpen}
        setOpen={setInitiateTransferOverlayOpen}
        merchantAccount={store.merchantAccount}
        defaultStoreId={store.id}
        i18n={i18n}
        trpc={trpc}
        currency={store.pspMerchantAccount.currency as CurrencyCode}
        onSuccess={() => {
          balanceAccountBalances.refetch();
          ongoingTransfers.refetch();
        }}
        tenantName={tenant?.name ?? ''}
      />
      {assignStoreFeeGroupData && (
        <AssignStoreTransactionFeeGroupPanel
          storeRow={assignStoreFeeGroupData}
          onClose={closeAssignStoreFeeGroupPanel}
          isOpen={!!assignStoreFeeGroupData}
          triggerRefetch={storeRefetch}
        />
      )}
      {updateSettlementDelayDays && (
        <UpdateStoreSettlementDelayDays
          storeRow={updateSettlementDelayDays}
          onClose={closeUpdateSettlementDelayDays}
          isOpen={!!openUpdateSettlementDelayDays}
          triggerRefetch={storeRefetch}
        />
      )}
    </div>
  );
};
