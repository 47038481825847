import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {StoreDetailsOutput, StoresListItem} from '@zentact/api/src/trpc/routers/storeRouter';
import {
  Button,
  InputSelect,
  SlideOverWithBrandedHeader,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';

export const getUpdateStoreSettlementDelayDaysSchema = () =>
  z.object({
    settlementDelayDays: z.string({
      // biome-ignore lint/style/useNamingConvention: Zod uses snake case
      required_error: t`Please select Settlement Delay`,
    }),
  });

export type UpdateStoreSettlementDelayDaysData = z.infer<
  ReturnType<typeof getUpdateStoreSettlementDelayDaysSchema>
>;

export type UpdateStoreSettlementDelayDaysProps = {
  storeRow: StoreDetailsOutput | StoresListItem;
  onClose: () => void;
  isOpen: boolean;
  triggerRefetch: () => void;
};

export const UpdateStoreSettlementDelayDays = ({
  storeRow,
  onClose,
  isOpen,
  triggerRefetch,
}: UpdateStoreSettlementDelayDaysProps) => {
  const {tenant} = useStore();
  const {i18n} = useLingui();
  const {balanceAccountId} = storeRow;
  const settlementDelayDaysOverride = storeRow.balanceAccount?.settlementDelayDaysOverride;
  const currentSettlementDelay =
    settlementDelayDaysOverride?.toString() || tenant.settlementDelayDays?.toString();

  const updateSettlementDelayForm = useForm<UpdateStoreSettlementDelayDaysData>({
    resolver: zodResolver(getUpdateStoreSettlementDelayDaysSchema()),
    defaultValues: {
      settlementDelayDays: currentSettlementDelay,
    },
  });
  const {
    handleSubmit,
    control,
    formState: {errors},
    watch,
  } = updateSettlementDelayForm;
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const settlementDelayDaysWatch = watch('settlementDelayDays');

  const updateMerchantSettlementDelay = trpc.balanceAccount.updateSettlementDelay.useMutation({
    onSuccess: () => {
      triggerRefetch();
      onClose();
      showSuccessNotification(
        t`Merchant settlement delay updated!`,
        t`You have successfully updated the settlement delay.`
      );
    },
    onError: error => {
      showErrorNotification(t`Error`, error.message);
    },
  });

  const onUpdateMerchantSettlementDelaySubmit = useCallback(
    (data: UpdateStoreSettlementDelayDaysData) => {
      if (!balanceAccountId) {
        return;
      }
      updateMerchantSettlementDelay.mutate({
        balanceAccountId,
        settlementDelayDays: parseInt(data.settlementDelayDays),
      });
    },
    [balanceAccountId]
  );

  const options = [
    {
      id: '1',
      label: 'T+1',
    },
    {
      id: '2',
      label: 'T+2',
    },
    {
      id: '3',
      label: 'T+3',
    },
  ];

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('Adjust Settlement Delay Settings')}
      text={i18n._(
        'Customize the duration it takes for transactions to be settled. This setting allows you to manage the timing between transaction authorization and fund settlement. Please note, depending on your contract with Adyen, opting for quicker settlements may incur higher charges.'
      )}
      closeHandler={onClose}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onUpdateMerchantSettlementDelaySubmit)}
              disabled={settlementDelayDaysWatch === currentSettlementDelay}
              isLoading={updateMerchantSettlementDelay.isLoading}
            >
              <Trans>Update</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onUpdateMerchantSettlementDelaySubmit)}>
        <div>
          <div className="flex flex-col gap-3">
            <Controller
              name="settlementDelayDays"
              control={control}
              render={({field}) => (
                <InputSelect
                  {...field}
                  className="mb-2"
                  label={t`Settlement Delay`}
                  options={options}
                  placeholder={t`Please select the value`}
                />
              )}
            />
            <ValidationError isVisible={Boolean(errors.settlementDelayDays)}>
              {errors.settlementDelayDays?.message}
            </ValidationError>
          </div>
        </div>
      </form>
    </SlideOverWithBrandedHeader>
  );
};
