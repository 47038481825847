import {customAlphabet} from 'nanoid';
import {MonthIndex} from '../../types';

const safeNoLookAlikesUppercaseAlphabet = 'BCDEFGHJKLMNPQRSTUVWYZ23456789';

export const userFacingNanoId = customAlphabet(safeNoLookAlikesUppercaseAlphabet);

export enum IdPrefix {
  MERCHANT_ACCOUNT = 'MER',
  SPLIT_CONFIGURATION_GROUP = 'SPT',
  MONTHLY_FEE_TRANSFER = 'MFE',
  ANNUAL_FEE_TRANSFER = 'AFE',
  INTERVAL_FEE_GROUP = 'IFG',
  TRANSACTION_FEE_TRANSFER = 'TFT',
  SAVED_PAYMENT_METHOD = 'SPM',
  SHOPPER_REFENCE = 'SHR',
  INTERNAL_FUNDS_TRANSFER = 'IFT',
  STORE_REFERENCE = 'STR',
  BALANCE_ACCOUNT = 'BA',
}

export const userFacingId = (prefix: IdPrefix) => {
  return `${prefix}${userFacingNanoId(10)}`;
};

export const MONTHLY_FEE_TRANSFER_REGEX = /MFE(\d{4})(\d{2})/;
export const ANNUAL_FEE_TRANSFER_REGEX = /AFE(\d{4})(\d{2})/;
export const TRANSACTION_FEE_TRANSFER_REGEX = /^TFT/;
export const INTERNAL_FUNDS_TRANSFER_REGEX = /^IFT/;

export const monthlyFeeTransferId = (year: number, month: MonthIndex) =>
  `${IdPrefix.MONTHLY_FEE_TRANSFER}${year}${(month + 1)
    .toString()
    .padStart(2, '0')}${userFacingNanoId(10)}`;

export const annualFeeTransferId = (year: number, month: MonthIndex) =>
  `${IdPrefix.ANNUAL_FEE_TRANSFER}${year}${(month + 1)
    .toString()
    .padStart(2, '0')}${userFacingNanoId(10)}`;
