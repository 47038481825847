import {useLingui} from '@lingui/react';
import {
  OrganizationAccountActivityDetailsItem,
  TenantAccountActivityDetailsItem,
} from '@zentact/api/src/trpc/routers/financeRouter';
import {CurrencyCode, LocaleCode, formatAmount, formatLocaleDate} from '@zentact/common';
import {formatTransferCategory} from '@zentact/common/src/utils/formatTransferCategory';
import {Link, generatePath} from 'react-router-dom';
import {SlideOverWithBrandedHeader} from '../../../index';
import {formatPayoutDateWithLocalTime} from '../payoutDateFormat';
import {formatDescription} from './columns';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  row: TenantAccountActivityDetailsItem | OrganizationAccountActivityDetailsItem | null;
  merchantDetailsPagePath?: string;
  organizationsPagePath?: string;
  paymentDetailsPath: string;
  activityDetailsPath: string;
  showStore?: boolean;
};

export const AccountActivityDetailsSidePanel = ({
  isOpen,
  onCancel,
  row,
  merchantDetailsPagePath,
  organizationsPagePath,
  paymentDetailsPath,
  activityDetailsPath,
  showStore,
}: Props) => {
  const {i18n} = useLingui();

  if (!row) {
    return null;
  }

  const isPortal = 'merchantAccount' in row;

  const merchantAccountName =
    'merchantAccount' in row ? row.merchantAccount.businessName : undefined;
  const merchantAccountId = 'merchantAccount' in row ? row.merchantAccount.id : undefined;
  const organizationName = 'organization' in row ? row.organization?.name : undefined;
  const organizationId = 'organization' in row ? row.organization?.id : undefined;

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('View Account Activity Details')}
      closeHandler={onCancel}
    >
      <div className="border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 pb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Transaction Date')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {formatLocaleDate(row.creationDate)}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Value Date')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {formatPayoutDateWithLocalTime(row.valueDate)}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Amount')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {formatAmount(
                row.amount * (row.direction === 'outgoing' ? -1 : 1),
                i18n.locale as LocaleCode,
                row.currency as CurrencyCode
              )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Category')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
              {formatTransferCategory(row)}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Type')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
              {row.type === 'internalTransfer' ? i18n._('Internal Transfer') : row.type}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Batch ID')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {row.batchId ? (
                <Link
                  className="text-sm text-primary-600"
                  onClick={onCancel}
                  to={`${activityDetailsPath}?batchId=${encodeURIComponent(row.batchId)}`}
                >
                  {row.batchId}
                </Link>
              ) : (
                i18n._('N/A')
              )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Transfer ID')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {row.reference || i18n._('N/A')}
            </dd>
          </div>
          {showStore && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Store ID')}</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {row.storeId || i18n._('N/A')}
              </dd>
            </div>
          )}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Payment Transaction ID')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {row.pspPaymentReference ? (
                <Link
                  className="text-sm text-primary-600"
                  to={generatePath(paymentDetailsPath, {
                    pspReferenceId: row.pspPaymentReference,
                  })}
                >
                  {row.pspPaymentReference}
                </Link>
              ) : (
                i18n._('N/A')
              )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Payment Merchant Reference')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {row.paymentMerchantReference || i18n._('N/A')}
            </dd>
          </div>

          {merchantAccountName && merchantDetailsPagePath && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Merchant Account')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <Link
                  to={generatePath(merchantDetailsPagePath, {
                    merchantAccountId: merchantAccountId,
                  })}
                  className="text-primary-600 hover:underline"
                >
                  {merchantAccountName}
                </Link>
              </dd>
            </div>
          )}
          {organizationName && organizationsPagePath && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Organization')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {organizationsPagePath ? (
                  <Link
                    to={`${organizationsPagePath}?organizationId=${organizationId}`}
                    className="text-primary-600 hover:underline"
                  >
                    {organizationName}
                  </Link>
                ) : (
                  organizationName
                )}
              </dd>
            </div>
          )}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Status')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
              {row.status}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Description')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {formatDescription(row.description, i18n, isPortal) || i18n._('N/A')}
            </dd>
          </div>
        </dl>
      </div>
    </SlideOverWithBrandedHeader>
  );
};
