import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';

export const useTenantIntervalFeesGroups = () => {
  const {pspMerchantAccountName} = useStore();
  const {data: tenantIntervalFeesGroups} = trpc.tenantIntervalFeesGroup.list.useQuery(
    {
      where: {
        ...(pspMerchantAccountName && {pspMerchantAccountName}),
      },
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  return {
    tenantIntervalFeesGroups: tenantIntervalFeesGroups?.rows ?? [],
  };
};
