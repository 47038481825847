import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {useNotification} from '../../hooks';
import {AlertOverlayWithConfirmation} from '../../overlays';

type RefundConfirmDialogProps = {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  businessName: string;
  registrationSessionId: string;
};

export const CancelOnboardingConfirmation = ({
  isOpen,
  setOpen,
  trpc,
  businessName,
  registrationSessionId,
  refetch,
}: RefundConfirmDialogProps) => {
  const {i18n} = useLingui();
  const {showSuccessNotification} = useNotification();

  const revokeMutation = trpc.merchantRegistration.revokeInvitation.useMutation();

  const handleCancelOnboarding = () => {
    revokeMutation.mutate(
      {registrationSessionId},
      {
        onSuccess: () => {
          refetch();
          setOpen(false);
          showSuccessNotification(
            i18n._('Invitation revoked!'),
            i18n._('You have successfully revoked the invitation.')
          );
        },
      }
    );
  };

  return (
    <div>
      <AlertOverlayWithConfirmation
        open={isOpen}
        setOpen={setOpen}
        handleAction={handleCancelOnboarding}
        localeText={{
          title: i18n._('Cancel onboarding'),
          description: i18n._(
            'Are you sure you would like to cancel merchant onboarding for {businessName}?',
            {businessName}
          ),
          confirm: i18n._('Cancel Onboarding'),
          cancel: i18n._('Cancel'),
        }}
      />
    </div>
  );
};
