import {I18n} from '@lingui/core';
import {Trans, t} from '@lingui/macro';
import {OrganizationListItem} from '@zentact/api/src/trpc/routers/organizationRouter';
import {formatLocaleDate} from '@zentact/common';
import {SlideOverWithBrandedHeader, getLocalizedOrganizationStatusMap} from '@zentact/ui-tailwind';

export type OtherRevenueViewPanelProps = {
  isOpen: boolean;
  onCancel: () => void;
  row: OrganizationListItem | null;
  i18n: I18n;
};

export const OrganizationViewPanel = ({
  isOpen,
  onCancel,
  row,
  i18n,
}: OtherRevenueViewPanelProps) => {
  if (!row) {
    return null;
  }

  const statusLabelMap = getLocalizedOrganizationStatusMap(i18n);

  const {
    name,
    id,
    referenceId,
    updatedAt,
    status,
    supportEmail,
    activeMerchantAccountsCount,
    onboardingMerchantAccountsCount,
    onboardingErrorsMerchantsCount,
  } = row;

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      closeHandler={onCancel}
      title={t`View Organization Details`}
    >
      <>
        <div className="border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 pb-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Organization Name</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {name}
              </dd>
            </div>
            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Organization ID</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {id}
              </dd>
            </div>
            {referenceId && (
              <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  <Trans>Reference ID</Trans>
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                  {referenceId}
                </dd>
              </div>
            )}
            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Last Active</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {formatLocaleDate(updatedAt)}
              </dd>
            </div>
            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Status</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {statusLabelMap[status]}
              </dd>
            </div>
            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Support Email</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {supportEmail || i18n._('N/A')}
              </dd>
            </div>
            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Active Merchants</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {activeMerchantAccountsCount}
              </dd>
            </div>
            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Onboarding Merchants</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {onboardingMerchantAccountsCount}
              </dd>
            </div>
            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <Trans>Merchants with onboarding errors</Trans>
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 break-words sm:col-span-2 sm:mt-0">
                {onboardingErrorsMerchantsCount}
              </dd>
            </div>
          </dl>
        </div>
      </>
    </SlideOverWithBrandedHeader>
  );
};
