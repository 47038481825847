import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useSplitConfigurationGroups, useTenantIntervalFeesGroups} from '@/hooks';
import {InviteMerchant} from '@/pages/customers/invite-merchant';
import {
  MerchantReviewPanel,
  MerchantReviewPanelProps,
} from '@/pages/customers/merchants-list/merchant-review-panel';
import {MerchantViewPanel} from '@/pages/customers/merchants-list/merchant-view-panel';
import {useStore} from '@/store';
import {ChevronDownIcon} from '@heroicons/react/24/outline';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {MerchantAccountPublicStatus, merchantsFiltersSchema} from '@zentact/common';
import {tailwindDefaultBreakpoints} from '@zentact/tailwind-config/tailwind-default-breakpoints';
import {
  Breadcrumbs,
  Button,
  MerchantFilters,
  ResetTableFiltersButton,
  SlideOverWithBrandedHeader,
  TableListSort,
  TableSortValue,
  Typography,
  useNotification,
  useToggleWithData,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {DeepPartial} from 'react-hook-form';
import {generatePath, useLocation, useNavigate} from 'react-router-dom';
import {useMediaQuery} from 'usehooks-ts';
import {z} from 'zod';
import {AssignRecurringFeeGroupPanel} from './assign-recurring-fee-group-panel';
import {exportToCsvMerchants} from './csv-export';
import {MerchantsList} from './merchants-list';
import {MerchantsStats} from './merchants-stats';
import {OrganizationFormData} from './organization-form/organization-form';

const breadcrumbs = () => [
  {name: t`Customers`, href: RoutePath.CUSTOMERS_MERCHANTS, current: false},
  {name: t`Merchants`, href: '#', current: true},
];

const adminMerchantsSearchParamsSchema = z.intersection(
  merchantsFiltersSchema,
  z.object({
    inviteOrganizationFormOpen: z
      .boolean()
      .or(z.string().transform(value => value === 'true'))
      .nullable()
      .optional(),
    filtersFormOpen: z
      .boolean()
      .or(z.string().transform(value => value === 'true'))
      .nullable()
      .optional(),
  })
);

export const Merchants = () => {
  const {organizationList, tenant, pspMerchantAccountName} = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(`(min-width: ${tailwindDefaultBreakpoints.lg})`);
  const {splitConfigurationGroups} = useSplitConfigurationGroups();
  const {tenantIntervalFeesGroups} = useTenantIntervalFeesGroups();

  const {typedSearchParams: typedSearchParamsAll, setTypedSearchParams} = useTypedSearchParams(
    adminMerchantsSearchParamsSchema
  );

  const {inviteOrganizationFormOpen, filtersFormOpen, ...typedSearchParams} = typedSearchParamsAll;
  const {
    status,
    organizationId,
    splitConfigurationGroupId,
    tenantIntervalFeesGroupId,
    businessName,
    reservesApplied,
  } = typedSearchParams;

  const organizationName = useMemo(
    () => organizationId && organizationList.find(org => org.id === organizationId)?.name,
    [organizationId, organizationList]
  );

  const inviteOrganizationFormData: DeepPartial<OrganizationFormData> =
    location?.state?.inviteOrganizationFormData;

  const [sort, setSort] = useState<TableSortValue<string> | null>(null);
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const organizationsSummary = trpc.organization.getOrganizationsSummary.useQuery(
    {
      ...(organizationId && {organizationId}),
      ...(splitConfigurationGroupId && {splitConfigurationGroupId}),
      ...(tenantIntervalFeesGroupId && {tenantIntervalFeesGroupId}),
      pspMerchantAccountName,
    },
    {
      refetchInterval: 5000,
      refetchOnWindowFocus: true,
    }
  );
  const merchantList = trpc.merchantAccount.getMerchantAccountsList.useQuery(
    {
      ...pagination,
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
      where: {
        ...(status?.length && {status}),
        ...(organizationId && {organizationId}),
        ...(businessName && {businessName}),
        ...(reservesApplied && {reservesApplied}),
        ...(splitConfigurationGroupId && {splitConfigurationGroupId}),
        ...(tenantIntervalFeesGroupId && {tenantIntervalFeesGroupId}),
        pspMerchantAccountName,
      },
    },
    {
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
    }
  );

  const {
    data: merchantDetailsRow,
    on: openMerchantDetailsPanel,
    off: closeMerchantDetailsPanel,
  } = useToggleWithData<MerchantAccountsListItem | null>();

  const {
    data: merchantReviewRow,
    on: openMerchantReviewPanel,
    off: closeMerchantReviewPanel,
  } = useToggleWithData<MerchantReviewPanelProps['merchantAccountRow']>();

  const {
    data: assignRecurringFeeGroupData,
    on: openAssignRecurringFeeGroupPanel,
    off: closeAssignRecurringFeeGroupPanel,
  } = useToggleWithData<MerchantAccountsListItem | MerchantAccountDetailsOutput | null>();

  const handleOpenMerchantDetails = useCallback(
    (row: MerchantAccountsListItem) => {
      if (isDesktop && row.merchantAccount?.id) {
        navigate(
          generatePath(RoutePath.MERCHANT_DETAILS, {merchantAccountId: row.merchantAccount.id})
        );
      } else {
        openMerchantDetailsPanel(row);
      }
    },
    [isDesktop, navigate, generatePath]
  );

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [
    status,
    organizationId,
    businessName,
    splitConfigurationGroupId,
    tenantIntervalFeesGroupId,
    sort,
  ]);

  const handleSelectStatus = useCallback(
    (selectedStatus: MerchantAccountPublicStatus[]) => {
      setTypedSearchParams(
        status &&
          status.length === selectedStatus.length &&
          selectedStatus.every(element => status.includes(element))
          ? {status: []}
          : {status: selectedStatus}
      );
    },
    [setTypedSearchParams, status]
  );

  const trpcContext = trpc.useUtils();
  const {i18n} = useLingui();

  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    if (!pspMerchantAccountName) {
      console.error('pspMerchantAccountName is not defined');
      return;
    }
    setCsvLoading(true);
    try {
      const fullMerchantsList = await trpcContext.merchantAccount.getMerchantAccountsList.fetch({
        ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
        where: {
          ...(status?.length && {status}),
          ...(organizationId && {organizationId}),
          ...(businessName && {businessName}),
          ...(reservesApplied && {reservesApplied}),
          ...(splitConfigurationGroupId && {splitConfigurationGroupId}),
          ...(tenantIntervalFeesGroupId && {tenantIntervalFeesGroupId}),
          pspMerchantAccountName,
        },
      });
      exportToCsvMerchants(fullMerchantsList, i18n);
      showSuccessNotification(t`Payments .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Payments .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, trpcContext, exportToCsvMerchants, sort, typedSearchParams]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="py-2">
        <Typography variant="header-page" className="flex">
          <Trans>Merchants</Trans>
          {organizationName && ` - ${organizationName}`}
        </Typography>
      </div>
      <div className="flex items-end justify-end gap-2 py-4 max-sm:flex-col sm:items-center">
        <div className="flex gap-2 font-normal max-sm:flex-wrap sm:items-center max-sm:w-full">
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit max-sm:w-full"
            isLoading={isCsvLoading}
            onClick={handleCsvExport}
            disabled={!merchantList.data || merchantList.data.rows.length === 0}
          >
            <Trans>Export to CSV</Trans>
          </Button>
          {!tenant?.features?.disableMerchantInviteInAdmin && (
            <Button
              type="button"
              variant="primary"
              size="md"
              className="w-fit max-sm:w-full"
              onClick={() => setTypedSearchParams({inviteOrganizationFormOpen: true})}
            >
              <Trans>Invite New</Trans>
            </Button>
          )}
        </div>
      </div>
      <MerchantsStats
        organizationsSummary={organizationsSummary.data}
        isLoading={organizationsSummary.isLoading}
        handleSelectStatus={handleSelectStatus}
        status={(status as MerchantAccountPublicStatus[]) || []}
      />

      <div className="flex justify-between gap-2 font-normal max-sm:flex-wrap sm:items-center">
        <TableListSort
          options={[
            {
              id: 'organizationName',
              label: i18n._('Organization'),
            },
            {
              id: 'updatedAt',
              label: i18n._('Last Active'),
            },
          ]}
          sort={sort}
          setSort={setSort}
        />
        <div className="flex justify-between gap-2">
          <div className="lg:hidden">
            <ResetTableFiltersButton
              defaultFilters={{}}
              activeFilters={typedSearchParams}
              setFilters={setTypedSearchParams}
            />
          </div>
          <Button
            type="button"
            size="sm"
            onClick={() => setTypedSearchParams({filtersFormOpen: true})}
            className="lg:hidden w-fit relative min-h-[2.25rem] shadow-none cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent sm:text-sm sm:leading-6 border-none"
          >
            <Trans>Filter</Trans>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Button>
        </div>
        <div className="hidden lg:block">
          <MerchantFilters
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
            status={status}
            organizationId={organizationId}
            businessName={businessName}
            spltiConfigurationGroupId={splitConfigurationGroupId}
            spltiConfigurationGroups={splitConfigurationGroups}
            tenantIntervalFeesGroupId={tenantIntervalFeesGroupId}
            tenantIntervalFeesGroups={tenantIntervalFeesGroups}
            organizationList={organizationList}
            reservesApplied={reservesApplied}
            enableManagedRisk={tenant.features?.enableManagedRisk}
          />
        </div>
        <SlideOverWithBrandedHeader
          isOpen={!!filtersFormOpen}
          title={'Filters'}
          closeHandler={() => setTypedSearchParams({filtersFormOpen: false})}
          panelClassName="w-screen pointer-events-auto lg:max-w-md"
        >
          <MerchantFilters
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
            status={status}
            organizationId={organizationId}
            businessName={businessName}
            spltiConfigurationGroupId={splitConfigurationGroupId}
            spltiConfigurationGroups={splitConfigurationGroups}
            tenantIntervalFeesGroupId={tenantIntervalFeesGroupId}
            tenantIntervalFeesGroups={tenantIntervalFeesGroups}
            organizationList={organizationList}
            reservesApplied={reservesApplied}
            enableManagedRisk={tenant.features?.enableManagedRisk}
          />
        </SlideOverWithBrandedHeader>
      </div>
      <div className="mt-4">
        {/* @ts-ignore */}
        <MerchantsList
          merchantList={merchantList.data}
          refetch={merchantList.refetch}
          filters={typedSearchParams || {}}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          openDetailsPanel={handleOpenMerchantDetails}
          openReviewPanel={openMerchantReviewPanel}
          openAssignRecurringFeeGroupPanel={openAssignRecurringFeeGroupPanel}
          isLoading={merchantList.isLoading}
        />
      </div>
      <InviteMerchant
        isOpen={!!inviteOrganizationFormOpen}
        onCancel={() => setTypedSearchParams({inviteOrganizationFormOpen: false})}
        onSuccess={() => {
          setTypedSearchParams({inviteOrganizationFormOpen: false});
          merchantList.refetch();
        }}
        organizationId={organizationId}
        organizationFormDefaultValue={inviteOrganizationFormData}
      />
      <MerchantViewPanel
        isOpen={!!merchantDetailsRow}
        onCancel={closeMerchantDetailsPanel}
        merchantAccountRow={merchantDetailsRow}
      />
      <MerchantReviewPanel
        isOpen={!!merchantReviewRow}
        onCancel={closeMerchantReviewPanel}
        merchantAccountRow={merchantReviewRow}
        onSubmit={() => merchantList.refetch()}
      />
      {assignRecurringFeeGroupData && (
        <AssignRecurringFeeGroupPanel
          merchantAccountRow={assignRecurringFeeGroupData}
          onClose={closeAssignRecurringFeeGroupPanel}
          isOpen={!!assignRecurringFeeGroupData}
          triggerRefetch={merchantList.refetch}
        />
      )}
    </div>
  );
};
