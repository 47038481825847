import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useLocale} from '@/hooks';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {
  ErrorCode,
  TenantIntervalFeeGroupPublicStatus,
  fromMinorUnits,
  isFormattedTrpcError,
  toMinorUnits,
} from '@zentact/common';
import {Breadcrumbs, Button, Typography, useNotification} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {
  RecurringFeeGroupForm,
  TenantIntervalFeeGroupFormData,
  getTenantIntervalFeeGroupFormSchema,
} from './recurring-fee-group-form/recurring-fee-group-form';

const getBreadCrumbs = () => [
  {name: t`Configure`, href: RoutePath.CONFIGURE, current: false, disabled: true},
  {name: t`Recurring Fee Groups`, href: RoutePath.RECURRING_FEE_GROUPS, current: false},
  {name: t`Create New Recurring Fee Group`, href: '#', current: true},
];

export const RecurringFeeGroupCreatePage = () => {
  const {pspMerchantAccountName, currency} = useStore();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const sourceFeeGroupId = useMemo(() => searchParams.get('sourceFeeGroupId'), [searchParams]);
  const isDuplicating = !!sourceFeeGroupId;
  const {data: defaultTenantIntervalFeeGroupData} = trpc.tenantIntervalFeesGroup.byId.useQuery(
    searchParams.get('sourceFeeGroupId') ?? '',
    {enabled: !!sourceFeeGroupId}
  );

  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {locale} = useLocale();

  const form = useForm<TenantIntervalFeeGroupFormData>({
    resolver: zodResolver(getTenantIntervalFeeGroupFormSchema(locale, currency)),
    defaultValues: {
      isActive: true,
      tenantIntervalFees: [{}],
    },
  });

  useEffect(() => {
    if (!defaultTenantIntervalFeeGroupData) {
      return;
    }

    const {name, status, perDeviceFee, tenantIntervalFees} = defaultTenantIntervalFeeGroupData;

    form.reset({
      name: name ? getDuplicateFeeGroupName(name) : '',
      isActive: status === TenantIntervalFeeGroupPublicStatus.ACTIVE || true,
      perDeviceFee: perDeviceFee ? fromMinorUnits(perDeviceFee, currency) : undefined,
      tenantIntervalFees: tenantIntervalFees
        ? tenantIntervalFees.map(fee => ({
            name: fee.name ?? '',
            amount: fromMinorUnits(fee.amount, currency),
            period: fee.period,
            annualFeeCollectMonth: fee.annualFeeCollectMonth
              ? fee.annualFeeCollectMonth.toString()
              : undefined,
          }))
        : [{}],
    });
  }, [defaultTenantIntervalFeeGroupData]);

  const {setError, setFocus, handleSubmit} = form;

  const createTenantIntervalFeeGroupMutation = trpc.tenantIntervalFeesGroup.create.useMutation({
    onSuccess: () => {
      showSuccessNotification(
        t`Recurring Fee Group is created`,
        t`Now you can apply this group to your new merchants`
      );
      navigate(RoutePath.RECURRING_FEE_GROUPS);
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      if (errorCode === ErrorCode.SPLIT_CONFIGURATION_GROUP_ALREADY_EXISTS) {
        setError('name', {
          type: 'manual',
          message: t`Fee Group with the same name already exists`,
        });
        setFocus('name');

        return;
      }

      showErrorNotification(
        t`Failed to create split configuration group`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const onSubmit = useCallback(
    (data: TenantIntervalFeeGroupFormData) => {
      createTenantIntervalFeeGroupMutation.mutate({
        name: data.name,
        pspMerchantAccountName,
        status: data.isActive
          ? TenantIntervalFeeGroupPublicStatus.ACTIVE
          : TenantIntervalFeeGroupPublicStatus.INACTIVE,
        perDeviceFee: data.perDeviceFee ? toMinorUnits(data.perDeviceFee, currency) : 0,
        tenantIntervalFees: data.tenantIntervalFees
          .filter(fee => fee.amount && fee.name)
          .map(fee => ({
            // biome-ignore lint/style/noNonNullAssertion: checked in the upper filter
            name: fee.name!,
            // biome-ignore lint/style/noNonNullAssertion: checked in the upper filter
            amount: toMinorUnits(fee.amount!, currency)!,
            period: fee.period,
            annualFeeCollectMonth: fee.annualFeeCollectMonth
              ? Number(fee.annualFeeCollectMonth)
              : undefined,
          })),
      });
    },
    [pspMerchantAccountName]
  );

  return (
    <div>
      <Breadcrumbs pages={getBreadCrumbs()} />
      <Typography variant="header-page" className="pt-4">
        {isDuplicating ? (
          <Trans>Duplicate Fee Group</Trans>
        ) : (
          <Trans>Create New Recurring Fee Group</Trans>
        )}
      </Typography>
      <div className="py-3 text-sm text-gray-700">
        <Trans>
          Set up how to charge your merchants. You can have a default set of fees and create custom
          configurations for different circumstances. You will apply these when adding a new
          merchant. Monthly fees will apply to all merchants onboarded by the 20th day of the month.
        </Trans>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RecurringFeeGroupForm form={form} />
        <footer className="flex justify-end mt-8">
          <Button
            size="lg"
            type="submit"
            className="w-fit"
            disabled={createTenantIntervalFeeGroupMutation.isLoading}
            isLoading={createTenantIntervalFeeGroupMutation.isLoading}
          >
            <Trans>Create Fee Group</Trans>
          </Button>
        </footer>
      </form>
    </div>
  );
};

const getDuplicateFeeGroupName = (name: string) => {
  const copyPattern: RegExp = /\(Copy(?: (\d+))?\)$/;

  // Check if groupName matches the pattern
  const match: RegExpExecArray | null = copyPattern.exec(name);

  if (!match) {
    // If pattern is not present, append "(Copy)"
    return `${name} (Copy)`;
  }

  // Extract the number part, if present
  const copyNumber: string | undefined = match[1];

  if (copyNumber === undefined) {
    // If no number is present, it's the second copy
    return name.replace(' (Copy)', ' (Copy 2)');
  }
  // If a number is present, increment it
  const incrementedNumber: number = parseInt(copyNumber, 10) + 1;
  return name.replace(copyPattern, `(Copy ${incrementedNumber})`);
};
