import {I18n} from '@lingui/core';
import {Trans} from '@lingui/macro';
import {createColumnHelper} from '@tanstack/react-table';
import {InternalTransfersListItem} from '@zentact/api/src/trpc/routers/balanceAccountRouter';
import {
  CurrencyCode,
  LocaleCode,
  TableColumnSize,
  formatAmount,
  formatLocaleDate,
} from '@zentact/common';
import type {InternalTransferStatus} from '@zentact/db';
import {FlatPillWithDot, TruncatedText} from '@zentact/ui-tailwind';
import {InternalTransfersActions} from './internal-transfers-actions';

export const getLocalizedInternalTransferStatusMap = (
  i18n: I18n
): Record<InternalTransferStatus, string> => ({
  // biome-ignore lint/style/useNamingConvention: <explanation>
  SCHEDULED: i18n._('Scheduled'),
  // biome-ignore lint/style/useNamingConvention: <explanation>
  WAITING_FUNDS_ARRIVAL: i18n._('Processing'),
  // biome-ignore lint/style/useNamingConvention: <explanation>
  CANCELING: i18n._('Canceling'),
  // biome-ignore lint/style/useNamingConvention: <explanation>
  COMPLETED: i18n._('Completed'),
  // biome-ignore lint/style/useNamingConvention: <explanation>
  CANCELED: i18n._('Canceled'),
});

const columnsHelper = createColumnHelper<InternalTransfersListItem>();

type Props = {
  i18n: I18n;
  locale: LocaleCode;
  refetch: () => void;
};
export const getInternalTransfersListColumns = ({i18n, locale, refetch}: Props) => {
  const statusLabelMap = getLocalizedInternalTransferStatusMap(i18n);

  return [
    columnsHelper.accessor('createdAt', {
      cell: info => formatLocaleDate(info.getValue(), 'shortWithTime'),
      header: () => <Trans>Created At</Trans>,
      size: TableColumnSize.M,
    }),
    columnsHelper.display({
      cell: props => props.row.original.referenceId,
      header: () => <Trans>Reference</Trans>,
      id: 'referenceId',
      meta: {
        collapseAt: 'sm',
      },
    }),
    columnsHelper.display({
      cell: props => props.row.original.merchantBalanceAccount?.store?.displayName ?? '-',
      header: () => <Trans>Store</Trans>,
      id: 'storeId',
      meta: {
        collapseAt: 'md',
      },
    }),
    columnsHelper.accessor('amount', {
      cell: ({row}) =>
        formatAmount(row.original.amount, locale, row.original.currency as CurrencyCode),
      header: () => <Trans>Amount</Trans>,
      meta: {},
      size: TableColumnSize.XS,
    }),
    columnsHelper.accessor('status', {
      cell: ({row}) => (
        <FlatPillWithDot
          color="yellow"
          label={statusLabelMap[row.original.status] || row.original.status}
        />
      ),
      meta: {
        collapseAt: 'lg',
      },
      header: () => <Trans>Account Status</Trans>,
      size: TableColumnSize.XS,
    }),
    columnsHelper.accessor('description', {
      cell: info => <TruncatedText text={info.getValue()} maxLength={30} />,
      header: () => <Trans>Description</Trans>,
      meta: {
        visibleAt: 'lg',
      },
      size: TableColumnSize.L,
    }),
    columnsHelper.display({
      id: 'actions',
      cell: props => (
        <div className="flex justify-center">
          <InternalTransfersActions row={props.row.original} refetch={refetch} />
        </div>
      ),
      size: TableColumnSize.ACTIONS,
    }),
  ];
};
