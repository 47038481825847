import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {feeGroupsSearchParamsSchema} from '@zentact/common';
import {TenantIntervalFeesGroupStatus} from '@zentact/db';
import {
  Breadcrumbs,
  Button,
  TableSortValue,
  Typography,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useEffect, useState} from 'react';
import {Link, generatePath, useNavigate} from 'react-router-dom';
import {RecurringFeeGroupsList} from './recurring-fee-groups-list';

const breadcrumbs = () => [
  {name: t`Configure`, href: RoutePath.CONFIGURE, current: false, disabled: true},
  {name: t`Recurring Fee Groups`, href: '#', current: true},
];

export const RecurrringFeeGroups = () => {
  const {pspMerchantAccountName} = useStore();
  const navigate = useNavigate();
  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(
    feeGroupsSearchParamsSchema
  );
  const [filters, setFilters] = useState<{
    name?: string;
    status?: TenantIntervalFeesGroupStatus[];
  }>({name: typedSearchParams.name});
  const [sort, setSort] = useState<TableSortValue<string> | null>(null);
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 15});

  const tenantIntervalFeeGroups = trpc.tenantIntervalFeesGroup.list.useQuery(
    {
      ...pagination,
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
      where: {
        ...(filters.name && {name: filters.name}),
        ...(filters.status?.length && {status: filters.status}),
        ...(pspMerchantAccountName && {pspMerchantAccountName}),
      },
    },
    {refetchOnWindowFocus: true, keepPreviousData: true}
  );

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
    setTypedSearchParams(filters);
  }, [filters, sort]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="flex justify-between gap-2 py-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Recurring Fee Groups</Trans>
        </Typography>
        <Link to={RoutePath.RECURRING_FEE_GROUP_CREATE}>
          <Button type="button" variant="primary" size="md" className="w-fit max-sm:w-full">
            <Trans>Add New</Trans>
          </Button>
        </Link>
      </div>
      <div className="mt-4">
        <RecurringFeeGroupsList
          tenantIntervalFeeGroupsList={tenantIntervalFeeGroups.data}
          refetch={tenantIntervalFeeGroups.refetch}
          filters={filters}
          setFilters={setFilters}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          onRowClick={tenantIntervalFeeGroupId =>
            navigate(
              generatePath(RoutePath.RECURRING_FEE_GROUP_DETAILS, {tenantIntervalFeeGroupId})
            )
          }
          isLoading={
            tenantIntervalFeeGroups.isLoading ||
            (tenantIntervalFeeGroups.isRefetching && tenantIntervalFeeGroups.isPreviousData)
          }
        />
      </div>
    </div>
  );
};
