export const capitalizeCustomAttribute = (input: string) => {
  return input
    .toLowerCase()
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\bid\b/g, 'ID') // Ensure 'ID' is uppercase
    .replace(/\b[a-z]/g, char => char.toUpperCase()); // Capitalize each word
};

export const capitalize = (input: string) => {
  return `${input.slice(0, 1).toUpperCase()}${input.slice(1)}`;
};
