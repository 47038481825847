import {useLingui} from '@lingui/react';
import {InputSearchSelect} from '../';

const ALL_ORGS = 'all';

type Props<T extends string> = {
  label?: string;
  selected?: T | null;
  className?: string;
  excludeDefaultOption?: boolean;
  disabled?: boolean;
  options: {
    id: T;
    name: string;
    className?: string;
    classNameActive?: string;
    tooltipText?: string;
  }[];
  onChange: (id?: T) => void;
};

export const EntityPicker = <T extends string>({
  label,
  options,
  onChange,
  className,
  selected,
  excludeDefaultOption,
  disabled,
}: Props<T>) => {
  const transformedOptions = options.map(({id, name, className, classNameActive, tooltipText}) => ({
    id: id as T | 'all',
    label: name,
    className,
    classNameActive,
    tooltipText,
  }));
  const {i18n} = useLingui();
  return (
    <InputSearchSelect
      label=""
      value={selected ?? (ALL_ORGS as T | 'all')}
      disabled={disabled}
      labelClassName="gap-0"
      placeholder={label || i18n._('All Organizations')}
      className={className ? className : 'mt-0 w-60'}
      onChange={item =>
        onChange(!Array.isArray(item) && item === ALL_ORGS ? undefined : (item as T))
      }
      options={
        excludeDefaultOption
          ? transformedOptions
          : [
              {
                id: ALL_ORGS as T | 'all',
                label: label || i18n._('All Organizations'),
              },
              ...transformedOptions,
            ]
      }
    />
  );
};
