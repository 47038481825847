import {I18n} from '@lingui/core';

export const supportedCountries = ['US', 'CA', 'AU', 'GB', 'NZ'] as const;
export const getCountryOptions = (
  i18n: I18n
): Array<{id: (typeof supportedCountries)[number]; label: string}> => {
  return [
    {
      id: 'US',
      label: i18n._('United States'),
    },
    {
      id: 'CA',
      label: i18n._('Canada'),
    },
    {
      id: 'AU',
      label: i18n._('Australia'),
    },
    {
      id: 'GB',
      label: i18n._('United Kingdom'),
    },
    {
      id: 'NZ',
      label: i18n._('New Zealand'),
    },
  ];
};
