import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {EllipsisVerticalIcon} from '@heroicons/react/20/solid';
import {Fragment, useState} from 'react';
import {Button} from '..';
import Portal from '../overlays/Portal';
import {cn} from '../utils';

type Props = {
  items: {
    name: string;
    onClick: () => void;
    itemClassName?: string;
  }[];
  buttonContent?: React.ReactNode;
  portalClassName?: string;
};

type Coords = {
  top: number;
  left: number;
};

export const DropDownMinimalMenuIcon = ({items, buttonContent, portalClassName}: Props) => {
  const [coords, setCoords] = useState<Coords>({top: 0, left: 0});

  const coordHandler = (e: React.MouseEvent<SVGElement | HTMLButtonElement>) => {
    const targetElement = e.target as HTMLButtonElement | SVGElement;
    const rect = targetElement.getBoundingClientRect();
    setCoords({
      left: Math.round(rect.x + rect.width),
      top: Math.round(rect.y + window.scrollY + rect.height),
    });
  };

  return (
    <Menu as="div" className="relative flex w-full text-left">
      <Menu.Button
        className={`flex items-center  ${
          !buttonContent
            ? 'text-gray-400 bg-gray-100 focus:ring-primary-500 hover:text-gray-600 rounded-full focus:ring-offset-2 focus:outline-none focus:ring-2  focus:ring-offset-gray-100'
            : 'w-full'
        }`}
      >
        {buttonContent ? (
          <Button variant="primary" onClick={coordHandler}>
            {buttonContent}
            <ChevronDownIcon className="w-5 h-5 ml-2" aria-hidden="true" />
          </Button>
        ) : (
          <>
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon onClick={coordHandler} className="w-5 h-5" aria-hidden="true" />
          </>
        )}
      </Menu.Button>

      <Portal>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            style={{
              top: `${coords?.top}px`,
              left: `${coords?.left}px`,
            }}
            className={cn(
              'absolute z-10 w-56 mt-2 origin-top-right transform -translate-x-full bg-white rounded-md shadow-lg ring-black/5 focus:outline-none',
              portalClassName
            )}
          >
            <div className="py-1">
              {items.map(item => (
                <Menu.Item key={item.name}>
                  {({active}) => (
                    <button
                      type="button"
                      onClick={item.onClick}
                      className={cn(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full px-4 py-2 text-left text-sm',
                        item.itemClassName
                      )}
                    >
                      {item.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Portal>
    </Menu>
  );
};
