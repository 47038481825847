import {Trans} from '@lingui/macro';
import {TenantIntervalFeesGroupListOutput} from '@zentact/api/src/trpc/routers/tenantIntervalFeesGroupRouter';
import {TableSortValue, TableWrapper} from '@zentact/ui-tailwind';
import {Dispatch, SetStateAction} from 'react';
import {getTenantIntervalFeeGroupColumns} from './columns';

type Props<TFilters extends Record<string, unknown>> = {
  tenantIntervalFeeGroupsList?: TenantIntervalFeesGroupListOutput;
  filters: TFilters;
  setFilters: Dispatch<SetStateAction<TFilters>>;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<SetStateAction<TableSortValue<string> | null>>;
  onRowClick?: (tenantIntervalFeeGroupId: string) => void;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
  refetch: () => void;
  isLoading?: boolean;
};

export const RecurringFeeGroupsList = <TFilters extends Record<string, unknown>>({
  tenantIntervalFeeGroupsList,
  filters,
  setFilters,
  sort,
  setSort,
  pagination,
  onPaginationChange,
  refetch,
  onRowClick,
  isLoading,
}: Props<TFilters>) => {
  return (
    <TableWrapper
      data={tenantIntervalFeeGroupsList?.rows ?? []}
      pageCount={tenantIntervalFeeGroupsList?.pagination.pageCount ?? 0}
      columns={getTenantIntervalFeeGroupColumns({
        refetch,
        openDetails: onRowClick,
      })}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        setFilters(prev => ({...prev, [id]: value}));
      }}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            <Trans>No Fee Groups</Trans>
          </h3>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      onRowClick={item => item && onRowClick?.(item.id)}
      excludeLastColumnFromRowClick
      isLoading={isLoading}
    />
  );
};
