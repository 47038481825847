import {Dialog, Transition} from '@headlessui/react';
import {ExclamationCircleIcon, ExclamationTriangleIcon} from '@heroicons/react/24/outline';
import {zodResolver} from '@hookform/resolvers/zod';
import {I18n} from '@lingui/core';
import {CurrencyCode, DEFAULT_CURRENCY, fromMinorUnits, toMinorUnits} from '@zentact/common';
import {Fragment, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Button, InputText} from '../forms';
import {ValidationError} from '../layout';
import {RefundFormDataType, takeRefundFormSchema} from '../layout/payments/refund-form';
import {cn} from '../utils';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleAction: (amount?: number) => void;
  localeText: {
    title: string;
    description: string;
    confirm: string;
    cancel: string;
  };
  mode?: 'error' | 'success';
  i18n: I18n;
  remainedAmount: number;
  currency: CurrencyCode;
};

export const RefundOverlay = ({
  open,
  setOpen,
  handleAction,
  localeText,
  mode = 'error',
  i18n,
  remainedAmount,
  currency,
}: Props) => {
  const cancelButtonRef = useRef(null);

  const {
    handleSubmit,
    register,
    formState: {errors},
    reset,
  } = useForm<RefundFormDataType>({
    resolver: zodResolver(takeRefundFormSchema(i18n, remainedAmount, currency)),
    defaultValues: {
      refundAmount: fromMinorUnits(remainedAmount, DEFAULT_CURRENCY),
    },
  });
  const [refundConfirmationLoading, setRefundConfirmationLoading] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onRefundFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Prevents button event to become queued up
    if (buttonRef.current?.disabled) return;

    handleSubmit(async data => {
      if (buttonRef.current) {
        buttonRef.current.disabled = true;
      }
      setRefundConfirmationLoading(true);
      await handleAction(toMinorUnits(data.refundAmount, DEFAULT_CURRENCY));
      setRefundConfirmationLoading(false);
    })();
  };

  const handleCloseDialog = () => {
    setOpen(false);
    reset();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleCloseDialog}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-50 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                <form
                  onSubmit={onRefundFormSubmit}
                  className="mt-4 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 md:col-span-2"
                >
                  <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div
                        className={cn(
                          'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
                          mode === 'error' ? 'bg-red-100' : 'bg-primary-100'
                        )}
                      >
                        {mode === 'error' ? (
                          <ExclamationTriangleIcon
                            className="w-6 h-6 text-red-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <ExclamationCircleIcon
                            className="w-6 h-6 text-primary-600"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {localeText.title}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">{localeText.description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <p className="text-sm">Refund Amount</p>
                    </div>
                    <InputText
                      {...register('refundAmount', {
                        valueAsNumber: true,
                      })}
                      addonBefore="$"
                      hasError={Boolean(errors.refundAmount)}
                    />
                    <ValidationError isVisible={Boolean(errors.refundAmount)}>
                      {errors.refundAmount?.message}
                    </ValidationError>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                    <Button
                      type="submit"
                      className={cn(
                        'inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto',
                        mode === 'error' && 'bg-red-600 hover:bg-red-500'
                      )}
                      isLoading={refundConfirmationLoading}
                      ref={buttonRef}
                    >
                      {localeText.confirm}
                    </Button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={handleCloseDialog}
                      ref={cancelButtonRef}
                    >
                      {localeText.cancel}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
