import {useLocale} from '@/hooks/useLocale';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {
  CurrencyCode,
  FEE_MIN_AND_MAX_RESTRICTIONS,
  LocaleCode,
  formatAmount,
  fromMinorUnits,
} from '@zentact/common';
import {
  Button,
  InputSelect,
  InputText,
  InputToggle,
  Label,
  Section,
  Typography,
  ValidationError,
  cn,
} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';
import z from 'zod';

const tenantIntervalFeesLimit = 5;

const getMonthSelectOptions = () => [
  {
    id: '0',
    label: t`January`,
  },
  {
    id: '1',
    label: t`February`,
  },
  {
    id: '2',
    label: t`March`,
  },
  {
    id: '3',
    label: t`April`,
  },
  {
    id: '4',
    label: t`May`,
  },
  {
    id: '5',
    label: t`June`,
  },
  {
    id: '6',
    label: t`July`,
  },
  {
    id: '7',
    label: t`August`,
  },
  {
    id: '8',
    label: t`September`,
  },
  {
    id: '9',
    label: t`October`,
  },
  {
    id: '10',
    label: t`November`,
  },
  {
    id: '11',
    label: t`December`,
  },
];

const getSplitFixedSchema = (
  locale: LocaleCode,
  min: number,
  max: number,
  tenantCurrency: CurrencyCode
) =>
  z.coerce
    .number({
      // biome-ignore lint/style/useNamingConvention: ZOD conventions
      invalid_type_error: t`Fee must be a number`,
      // biome-ignore lint/style/useNamingConvention: ZOD conventions
      required_error: t`Fee is required`,
    })
    .multipleOf(0.01, t`Fee must be a multiple of 0.01`)
    .min(
      fromMinorUnits(min, tenantCurrency),
      t`Fee must be greater than or equal to ${formatAmount(min, locale, tenantCurrency)}`
    )
    .max(
      fromMinorUnits(max, tenantCurrency),
      t`Fee must be less than or equal to ${formatAmount(max, locale, tenantCurrency)}`
    );

export const getTenantIntervalFeeGroupFormSchema = (
  locale: LocaleCode,
  tenantCurrency: CurrencyCode
) =>
  z
    .object({
      name: z
        .string()
        .min(1, t`Name is required`)
        .max(100, t`Name must be less than 100 characters`),
      isActive: z.boolean(),
      perDeviceFee: getSplitFixedSchema(
        locale,
        0,
        FEE_MIN_AND_MAX_RESTRICTIONS.maxPerDeviceFee,
        tenantCurrency
      ).optional(),
      tenantIntervalFees: z.array(
        z.object({
          name: z.string().optional(),
          amount: getSplitFixedSchema(
            locale,
            0,
            FEE_MIN_AND_MAX_RESTRICTIONS.maxTenantIntervalFee,
            tenantCurrency
          ).optional(),
          period: z.enum(['MONTHLY', 'ANNUAL']),
          annualFeeCollectMonth: z.string().optional(),
        })
      ),
    })
    .superRefine((data, ctx) => {
      data.tenantIntervalFees.forEach((fee, index) => {
        if (fee.name || fee.amount) {
          if (!fee.name) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['tenantIntervalFees', index, 'name'],
              message: t`Monthly fee must have a name`,
            });
          }

          if (!fee.amount) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['tenantIntervalFees', index, 'amount'],
              message: t`Monthly fee has a name but missing the amount`,
            });
          }
        }
      });
    });

export type TenantIntervalFeeGroupFormData = z.infer<
  ReturnType<typeof getTenantIntervalFeeGroupFormSchema>
>;

type Props = {
  form: UseFormReturn<TenantIntervalFeeGroupFormData>;
  readOnly?: boolean;
  merchantsCount?: number;
};

export const RecurringFeeGroupForm = ({form, readOnly, merchantsCount}: Props) => {
  const {currency} = useStore();
  const {locale} = useLocale();
  const {
    register,
    control,
    formState: {errors},
    watch,
    setValue,
    getValues,
  } = form;
  const isActive = watch('isActive');
  const tenantIntervalFees = watch('tenantIntervalFees') ?? [];

  const perDeviceFee = watch('perDeviceFee');

  const readOnlyClassName = readOnly ? 'bg-gray-100 text-gray-400' : '';

  const onAddNewTenantIntervalFee = useCallback(() => {
    if (tenantIntervalFees.length < tenantIntervalFeesLimit) {
      setValue('tenantIntervalFees', [...tenantIntervalFees, {period: 'MONTHLY'}]);
    }
  }, [tenantIntervalFees, setValue]);

  const onRemoveTenantIntervalFee = useCallback(
    (index: number) => {
      const newTenantIntervalFees = [
        ...tenantIntervalFees.slice(0, index),
        ...tenantIntervalFees.slice(index + 1),
      ];
      setValue('tenantIntervalFees', newTenantIntervalFees);
    },
    [tenantIntervalFees, setValue]
  );

  const currencySymbol = (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

  return (
    <div>
      <Section title={<Trans>General</Trans>}>
        <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
          <Label text={t`Name`}>
            <InputText
              {...register('name', {required: true})}
              hasError={Boolean(errors.name)}
              readOnly={readOnly}
              disabled={readOnly}
              placeholder={t`Fee Group Name`}
              className={readOnlyClassName}
            />
            <ValidationError isVisible={Boolean(errors.name)}>
              {errors.name?.message}
            </ValidationError>
          </Label>
          <div>
            <InputToggle
              toggleSize="lg"
              {...register('isActive')}
              readOnly={readOnly}
              disabled={readOnly}
              label={<Trans>Active</Trans>}
            />
            <Typography variant="field-helper" className="mt-2">
              {isActive && <Trans>All merchants can be added to this fee group</Trans>}

              {!isActive && !merchantsCount && (
                <Trans>New merchants cannot be assigned to this fee group</Trans>
              )}

              {!isActive && merchantsCount && (
                <Trans>
                  This fee group still applies to {merchantsCount} merchants, but new merchants
                  cannot use this fee group
                </Trans>
              )}
            </Typography>
          </div>
        </div>
      </Section>
      <Section
        title={<Trans>Per Device Fee</Trans>}
        subTitle={
          <Trans>
            This is an optional monthly fee that will be charged per terminal or other device
            assigned to a merchant. Fee will be applied on the 1st of each month.
          </Trans>
        }
      >
        <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
          <Label text={t`Fee Amount (${currencySymbol})`}>
            <InputText
              {...register('perDeviceFee')}
              value={readOnly && perDeviceFee ? perDeviceFee.toFixed(2) : perDeviceFee}
              readOnly={readOnly}
              disabled={readOnly}
              className={readOnlyClassName}
              addonBefore={currencySymbol}
              hasError={Boolean(errors.perDeviceFee)}
            />
            <ValidationError isVisible={Boolean(errors.perDeviceFee)}>
              {errors.perDeviceFee?.message}
            </ValidationError>
          </Label>
        </div>
      </Section>
      {tenantIntervalFees.length > 0 && (
        <Section
          title={<Trans>Recurring Fees</Trans>}
          subTitle={
            <Trans>
              This is an optional custom fee that can be charged to your customers once per month or
              annualy. Fee will be applied on the 1st of each month.
            </Trans>
          }
        >
          <div className="flex flex-col divide-y divide-gray-200 gap-y-5">
            {tenantIntervalFees.map((_tenantIntervalFees, tenantIntervalFeeId) => (
              <div
                className={cn('flex flex-col', tenantIntervalFeeId > 0 ? 'pt-5' : '')}
                key={`tenantIntervalFeeId-${tenantIntervalFeeId}`}
              >
                <div className="flex gap-x-8">
                  <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
                    <Label text={t`Fee Name`}>
                      <InputText
                        {...register(`tenantIntervalFees.${tenantIntervalFeeId}.name`)}
                        hasError={Boolean(errors.tenantIntervalFees?.[tenantIntervalFeeId]?.name)}
                        placeholder={t`e.g. Monthly Maintenance Fee & Account Fee`}
                        readOnly={readOnly}
                        disabled={readOnly}
                        className={readOnlyClassName}
                      />
                      <ValidationError
                        isVisible={Boolean(errors.tenantIntervalFees?.[tenantIntervalFeeId]?.name)}
                      >
                        {errors.tenantIntervalFees?.[tenantIntervalFeeId]?.name?.message}
                      </ValidationError>
                    </Label>
                    <Controller
                      control={control}
                      defaultValue="MONTHLY"
                      name={`tenantIntervalFees.${tenantIntervalFeeId}.period`}
                      render={({field}) => (
                        <>
                          <InputSelect
                            label={t`Billing Frequency`}
                            value={field.value}
                            options={[
                              {
                                id: 'MONTHLY',
                                label: t`Monthly`,
                              },
                              {
                                id: 'ANNUAL',
                                label: t`Annual`,
                              },
                            ]}
                            onChange={value => {
                              field.onChange(value);
                            }}
                            disabled={readOnly}
                          />
                          <ValidationError
                            isVisible={Boolean(
                              errors.tenantIntervalFees?.[tenantIntervalFeeId]?.period?.message
                            )}
                          >
                            {errors.tenantIntervalFees?.[tenantIntervalFeeId]?.period?.message}
                          </ValidationError>
                        </>
                      )}
                    />
                    {tenantIntervalFees[tenantIntervalFeeId]?.period === 'ANNUAL' && (
                      <Controller
                        control={control}
                        defaultValue="0"
                        name={`tenantIntervalFees.${tenantIntervalFeeId}.annualFeeCollectMonth`}
                        render={({field}) => (
                          <>
                            <InputSelect
                              label={t`Billing Month`}
                              value={field.value}
                              options={getMonthSelectOptions()}
                              onChange={value => {
                                field.onChange(value);
                              }}
                              disabled={readOnly}
                            />
                            <ValidationError
                              isVisible={Boolean(
                                errors.tenantIntervalFees?.[tenantIntervalFeeId]
                                  ?.annualFeeCollectMonth?.message
                              )}
                            >
                              {
                                errors.tenantIntervalFees?.[tenantIntervalFeeId]
                                  ?.annualFeeCollectMonth?.message
                              }
                            </ValidationError>
                          </>
                        )}
                      />
                    )}
                    <Label text={t`Fee Amount (${currencySymbol})`}>
                      <InputText
                        {...register(`tenantIntervalFees.${tenantIntervalFeeId}.amount`)}
                        value={
                          readOnly
                            ? Number(
                                getValues(`tenantIntervalFees.${tenantIntervalFeeId}.amount`)
                              ).toFixed(2)
                            : getValues(`tenantIntervalFees.${tenantIntervalFeeId}.amount`)
                        }
                        addonBefore={currencySymbol}
                        readOnly={readOnly}
                        disabled={readOnly}
                        className={readOnlyClassName}
                        hasError={Boolean(errors.tenantIntervalFees?.[tenantIntervalFeeId]?.amount)}
                      />
                      <ValidationError
                        isVisible={Boolean(
                          errors.tenantIntervalFees?.[tenantIntervalFeeId]?.amount
                        )}
                      >
                        {errors.tenantIntervalFees?.[tenantIntervalFeeId]?.amount?.message}
                      </ValidationError>
                    </Label>
                    {!readOnly && tenantIntervalFeeId > 0 && (
                      <Button
                        size="lg"
                        className="bg-red-600 w-fit hover:bg-red-500 focus:ring-red-500"
                        type="button"
                        onClick={() => onRemoveTenantIntervalFee(tenantIntervalFeeId)}
                      >
                        <Trans>Remove Fee</Trans>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}

            {tenantIntervalFees.length < tenantIntervalFeesLimit && !readOnly && (
              <div>
                <Button
                  size="lg"
                  className="mt-6 w-fit"
                  type="button"
                  onClick={onAddNewTenantIntervalFee}
                >
                  <Trans>Add New</Trans>
                </Button>
              </div>
            )}
          </div>
        </Section>
      )}
    </div>
  );
};
