import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {Trans, t} from '@lingui/macro';
import {
  TenantIntervalFeesGroupByIdOutput,
  TenantIntervalFeesGroupListItemOutput,
} from '@zentact/api/src/trpc/routers/tenantIntervalFeesGroupRouter';
import {TenantIntervalFeeGroupPublicStatus} from '@zentact/common';
import {
  AlertOverlayWithConfirmation,
  DropDownMinimalMenuIcon,
  useNotification,
} from '@zentact/ui-tailwind';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

type TenantIntervalFeeGroupData =
  | TenantIntervalFeesGroupListItemOutput
  | TenantIntervalFeesGroupByIdOutput;

type Props = {
  tenantIntervalFeeGroup: TenantIntervalFeeGroupData;
  refetchData: () => void;
  openDetails?: (tenantIntervalFeeGroupId: string) => void;
  view?: 'button' | 'icon';
};

enum TenantIntervalFeeGroupAction {
  VIEW_DETAILS = 'details',
  MAKE_INACTIVE = 'makeInactive',
  MAKE_ACTIVE = 'makeActive',
  VIEW_ASSIGNED_MERCHANTS = 'viewAssignedMerchants',
  DUPLICATE = 'duplicate',
}

const statusToItemsMap = (
  action: (action: TenantIntervalFeeGroupAction) => void,
  {
    openDetails,
  }: {
    openDetails?: (tenantIntervalFeeGroupId: string) => void;
  }
): Record<
  TenantIntervalFeeGroupPublicStatus,
  {name: string; onClick: () => void; itemClassName?: string}[]
> => {
  const detailsAction = openDetails
    ? [
        {
          name: t`Details`,
          onClick: () => action(TenantIntervalFeeGroupAction.VIEW_DETAILS),
        },
      ]
    : [];
  const viewAssignedMerchantsAction = {
    name: t`View Assigned Merchants`,
    onClick: () => action(TenantIntervalFeeGroupAction.VIEW_ASSIGNED_MERCHANTS),
  };
  const duplicateAction = {
    name: t`Duplicate`,
    onClick: () => action(TenantIntervalFeeGroupAction.DUPLICATE),
  };

  return {
    [TenantIntervalFeeGroupPublicStatus.ACTIVE]: [
      ...detailsAction,
      viewAssignedMerchantsAction,
      duplicateAction,
      {
        name: t`Make Inactive`,
        onClick: () => action(TenantIntervalFeeGroupAction.MAKE_INACTIVE),
        itemClassName: 'text-red-600',
      },
    ],
    [TenantIntervalFeeGroupPublicStatus.INACTIVE]: [
      ...detailsAction,
      viewAssignedMerchantsAction,
      duplicateAction,
      {
        name: t`Make Active`,
        onClick: () => action(TenantIntervalFeeGroupAction.MAKE_ACTIVE),
      },
    ],
    [TenantIntervalFeeGroupPublicStatus.ARCHIVED]: [...detailsAction, duplicateAction],
  };
};

export const RecurringFeeGroupActions = ({
  tenantIntervalFeeGroup,
  refetchData,
  openDetails,
  view = 'icon',
}: Props) => {
  const navigate = useNavigate();
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const [makeActiveConfirmationOpen, setMakeActiveConfirmationOpen] = useState(false);
  const [makeInactiveConfirmationOpen, setMakeInactiveConfirmationOpen] = useState(false);
  const updateTenantIntervalFeeGroupMutation = trpc.tenantIntervalFeesGroup.updateById.useMutation({
    onSuccess: () => {
      refetchData();
      setMakeActiveConfirmationOpen(false);
      setMakeInactiveConfirmationOpen(false);
    },
    onError: error => {
      showErrorNotification(t`Error`, error.message);
    },
  });

  const handleAction = (action: string) => {
    if (action === TenantIntervalFeeGroupAction.VIEW_DETAILS && openDetails) {
      openDetails(tenantIntervalFeeGroup.id);
      return;
    }

    if (action === TenantIntervalFeeGroupAction.MAKE_INACTIVE) {
      setMakeInactiveConfirmationOpen(true);
      return;
    }

    if (action === TenantIntervalFeeGroupAction.MAKE_ACTIVE) {
      setMakeActiveConfirmationOpen(true);
      return;
    }

    if (action === TenantIntervalFeeGroupAction.VIEW_ASSIGNED_MERCHANTS) {
      navigate(
        `${RoutePath.CUSTOMERS_MERCHANTS}?tenantIntervalFeesGroupId=${tenantIntervalFeeGroup.id}`
      );
      return;
    }

    if (action === TenantIntervalFeeGroupAction.DUPLICATE) {
      navigate(
        `${RoutePath.RECURRING_FEE_GROUP_CREATE}?sourceFeeGroupId=${tenantIntervalFeeGroup.id}`
      );
      return;
    }
  };

  const handleMakeActive = () => {
    updateTenantIntervalFeeGroupMutation.mutate(
      {
        tenantIntervalFeeGroupId: tenantIntervalFeeGroup.id,
        status: TenantIntervalFeeGroupPublicStatus.ACTIVE,
      },
      {
        onSuccess: () => {
          showSuccessNotification(
            t`Recurring Fee group "${tenantIntervalFeeGroup.name}" has been activated.`
          );
        },
      }
    );
  };

  const handleMakeInactive = () => {
    updateTenantIntervalFeeGroupMutation.mutate(
      {
        tenantIntervalFeeGroupId: tenantIntervalFeeGroup.id,
        status: TenantIntervalFeeGroupPublicStatus.INACTIVE,
      },
      {
        onSuccess: () => {
          showSuccessNotification(
            t`Recurring Fee group "${tenantIntervalFeeGroup.name}" has been deactivated.`
          );
        },
      }
    );
  };

  const menuItems = statusToItemsMap(handleAction, {
    openDetails,
  })[tenantIntervalFeeGroup.status];

  if (!menuItems) {
    return null;
  }

  return (
    <>
      <div className="w-fit">
        <DropDownMinimalMenuIcon
          items={menuItems}
          buttonContent={view === 'button' ? <Trans>Actions</Trans> : undefined}
        />
      </div>
      <AlertOverlayWithConfirmation
        open={makeActiveConfirmationOpen}
        setOpen={setMakeActiveConfirmationOpen}
        handleAction={handleMakeActive}
        localeText={{
          title: t`Activate Fee Group`,
          description: t`Are you sure you want to activate the fee group "${tenantIntervalFeeGroup.name}"?`,
          confirm: t`Activate`,
          cancel: t`Cancel`,
        }}
        mode="success"
        loading={updateTenantIntervalFeeGroupMutation.isLoading}
      />
      <AlertOverlayWithConfirmation
        open={makeInactiveConfirmationOpen}
        setOpen={setMakeInactiveConfirmationOpen}
        handleAction={handleMakeInactive}
        localeText={{
          title: t`Deactivate Fee Group`,
          description: (
            <span>
              <Trans>
                Are you sure you want to deactivate the fee group{' '}
                <strong>{tenantIntervalFeeGroup.name}</strong>?
              </Trans>
              <span className="flex mt-2">
                <Trans>
                  This fee group still applies to assigned merchants, but new merchants cannot use
                  this fee group.
                </Trans>
              </span>
            </span>
          ),
          confirm: t`Deactivate`,
          cancel: t`Cancel`,
        }}
        loading={updateTenantIntervalFeeGroupMutation.isLoading}
      />
    </>
  );
};
