import {I18n} from '@lingui/core';
import {MerchantAccountPublicStatus} from '@zentact/common';
import {DateTime} from 'luxon';

export const getLocalizedStatusMap = (
  i18n: I18n,
  expiresAt?: string
): Record<MerchantAccountPublicStatus, string> => ({
  [MerchantAccountPublicStatus.ACTIVE]: i18n._('Active'),
  [MerchantAccountPublicStatus.INITIATED]: i18n._('Invite Initiated'),
  [MerchantAccountPublicStatus.INVITED]: expiresAt
    ? i18n._('Invite Sent: {count}d left', {
        count: Math.floor(Math.abs(DateTime.now().diff(DateTime.fromISO(expiresAt), 'days').days)),
      })
    : i18n._('Invite Sent'),
  [MerchantAccountPublicStatus.INVITE_ACCEPTED]: i18n._('Invite Accepted'),
  [MerchantAccountPublicStatus.APPLICATION_IN_PROGRESS]: i18n._('Application In Progress'),
  [MerchantAccountPublicStatus.APPLICATION_IN_REVIEW]: i18n._('Application In Review'),
  [MerchantAccountPublicStatus.INVITE_EXPIRED]: i18n._('Invite Expired'),
  [MerchantAccountPublicStatus.INVITE_REVOKED]: i18n._('Invite Revoked'),
  [MerchantAccountPublicStatus.DEACTIVATED]: i18n._('Deactivated'),
  [MerchantAccountPublicStatus.APPLICATION_REVOKED]: i18n._('Application Revoked'),
  [MerchantAccountPublicStatus.REJECTED]: i18n._('Application Error'),
  [MerchantAccountPublicStatus.REVIEW_REJECTED]: i18n._('Review Rejected'),
  [MerchantAccountPublicStatus.NEEDS_ORGANIZATION]: i18n._('Needs Organization Assignment'),
  [MerchantAccountPublicStatus.ASSIGNING_ORGANIZATION]: i18n._('Organization Assigning'),
  [MerchantAccountPublicStatus.ADDITIONAL_DATA_REQUIRED]: i18n._(
    'Application: Additional Data Required'
  ),
});
