import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {StoreDetailsOutput, StoresListItem} from '@zentact/api/src/trpc/routers/storeRouter';
import {ErrorCode, isFormattedTrpcError} from '@zentact/common';
import {
  AlertOverlayWithConfirmation,
  DropDownMinimalMenuIcon,
  useNotification,
} from '@zentact/ui-tailwind';
import {useState} from 'react';

type Props = {
  row: StoreDetailsOutput | StoresListItem;
  openAssignStoreFeeGroupPanel: (row: StoreDetailsOutput | StoresListItem | null) => void;
  openEditStorePanel: () => void;
  openUpdateSettlementDelayDays: (row: StoreDetailsOutput | StoresListItem | null) => void;
  viewType: 'dots-button' | 'chevron-button';
  triggerRefetch: () => void;
};

export const StoreActions = ({
  row,
  openAssignStoreFeeGroupPanel,
  openEditStorePanel,
  openUpdateSettlementDelayDays,
  viewType,
  triggerRefetch,
}: Props) => {
  const {tenant} = useStore();
  const [deactivateConfirmationOpen, setDeactivateConfirmationOpen] = useState(false);
  const [reactivateConfirmationOpen, setReactivateConfirmationOpen] = useState(false);
  const [closeConfirmationOpen, setCloseConfirmationOpen] = useState(false);

  const {showSuccessNotification, showErrorNotification} = useNotification();

  const multiStoreFeatureEnabled = !!tenant.features?.enableMultiStore;

  const options: {name: string; onClick: () => void; itemClassName?: string}[] = [
    {name: t`Edit Store`, onClick: () => openEditStorePanel()},
    {name: t`Assign Transaction Fee Group`, onClick: () => openAssignStoreFeeGroupPanel(row)},
    ...(!row?.merchantAccount.managedRiskEnabled
      ? [{name: t`Update Settlement Delay`, onClick: () => openUpdateSettlementDelayDays(row)}]
      : []),
    ...(row.status === 'ACTIVE' && !multiStoreFeatureEnabled
      ? [
          {
            name: t`Deactivate`,
            onClick: () => setDeactivateConfirmationOpen(true),
            itemClassName: 'text-red-500',
          },
        ]
      : []),
    ...(row.status === 'INACTIVE'
      ? [
          {
            name: t`Reactivate`,
            onClick: () => setReactivateConfirmationOpen(true),
          },
          ...(!multiStoreFeatureEnabled
            ? [
                {
                  name: t`Close Permanently`,
                  onClick: () => setCloseConfirmationOpen(true),
                  itemClassName: 'text-red-500',
                },
              ]
            : []),
        ]
      : []),
  ];

  const updateStoreStatusMutation = trpc.store.updateStoreStatus.useMutation({
    onSuccess: () => {
      triggerRefetch();
      setDeactivateConfirmationOpen(false);
      setReactivateConfirmationOpen(false);
      setCloseConfirmationOpen(false);
      showSuccessNotification(
        t`Store status updated`,
        t`You have successfully updated store status.`
      );
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;
      if (errorCode === ErrorCode.BALANCE_ACCOUNT_HAS_BALANCE) {
        showErrorNotification(
          t`The store has a non zero balance`,
          t`Please wait until the balance is be paid out to the bank account or transfer it manualy from the store page before trying again.`
        );
      }
      showErrorNotification(t`Something went wrong. Please try again later.`);
    },
  });

  return (
    <>
      <DropDownMinimalMenuIcon
        items={options}
        buttonContent={viewType === 'chevron-button' ? <Trans>Actions</Trans> : undefined}
        portalClassName="w-60"
      />
      {deactivateConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={deactivateConfirmationOpen}
          setOpen={setDeactivateConfirmationOpen}
          handleAction={() =>
            updateStoreStatusMutation.mutate({storeId: row.id, status: 'INACTIVE'})
          }
          loading={updateStoreStatusMutation.isLoading}
          localeText={{
            title: t`Deactivate Store`,
            description: t`Are you sure you want to deactivate ${row.displayName}?`,
            confirm: t`Deactivate`,
            cancel: t`Cancel`,
          }}
        />
      )}
      {reactivateConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={reactivateConfirmationOpen}
          setOpen={setReactivateConfirmationOpen}
          handleAction={() => updateStoreStatusMutation.mutate({storeId: row.id, status: 'ACTIVE'})}
          loading={updateStoreStatusMutation.isLoading}
          localeText={{
            title: t`Reactivate Store`,
            description: t`Are you sure you want to reactivate ${row.displayName}?`,
            confirm: t`Reactivate`,
            cancel: t`Cancel`,
          }}
        />
      )}
      {closeConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={closeConfirmationOpen}
          setOpen={setCloseConfirmationOpen}
          handleAction={() => updateStoreStatusMutation.mutate({storeId: row.id, status: 'CLOSED'})}
          loading={updateStoreStatusMutation.isLoading}
          localeText={{
            title: t`Close Store Permanently`,
            description: t`Are you sure you want to permanently close ${row.displayName}?`,
            confirm: t`Close Store`,
            cancel: t`Cancel`,
          }}
        />
      )}
    </>
  );
};
